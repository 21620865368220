import { css } from "@emotion/react";

export const signOutBtn = css({
  background: "#1F8290",
  color: "#fff",
  maxWidth: "200px",
  width: "50%",
  height: "40px",
  border: "none",
  borderRadius: "10px",
  cursor: "pointer",
  marginBottom: "0px",
  ":hover": {
    background: "#6C757D",
  },
  ":active": {
    background: "#6C757D",
  },
});

export const navMenu = css({
  backgroundColor: "#000000",
  fontSize: "1.45rem",
});

export const secondaryNavMenu = css({
  backgroundColor: "#1F8290",
});

export const alertBlock = css({
  backgroundColor: "#DA2128",
  fontSize: "1.45rem",
  fontWeight: "400",
  lineHeight: "20px",
});

export const logoTitle = css({
  fontSize: "1.6rem",
  fontWeight: "600",
  color: "#fff",
  marginBottom: "0px",
});

export const secondaryNavTitle = css({ fontSize: "1.4rem", color: "#fff" });
