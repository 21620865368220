import { faqQuestions } from "./constants";
import {
  answerStyle,
  footerWrapper,
  headingTitle,
  questionStyle,
} from "./Footer.styles";

export const Footer = () => {
  return (
    <div className="px-5 pb-5 mt-5" css={footerWrapper}>
      <p css={headingTitle} className="pb-4">
        Frequently Asked Questions
      </p>

      {faqQuestions.map((question, index) => (
        <div className="mb-4" key={index}>
          <p css={questionStyle} className="mb-1">
            {question.title}
          </p>
          <p css={answerStyle} className="mb-3">
            {question.text}
          </p>
        </div>
      ))}
    </div>
  );
};
