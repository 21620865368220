import * as Yup from "yup";

export const OrderFormSchema = Yup.object({
  first_name: Yup.string()
    .required("First Name is a required field")
    .test(
      "length",
      "Must be at least 2 letters",
      (val) => (val || "").replace(/[\s+]/g, "").length >= 2
    )
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  last_name: Yup.string()
    .required("Last Name is a required field")
    .test(
      "length",
      "Must be at least 2 letters",
      (val) => (val || "").replace(/[\s+]/g, "").length >= 2
    )
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-zA-Z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  phone: Yup.string()
    .required("Phone Number is a required field")
    .matches(/(^[0-9 +]+$)/, "Only numbers are allowed")
    .min(10, "Must be at least 10 characters"),
  delivery_address_full: Yup.string().when("custom_address", {
    is: false,
    then: Yup.string().required("Address is a required field!"),
  }),
  delivery_address_1: Yup.string().when("custom_address", {
    is: true,
    then: Yup.string()
      .required("Address is a required field!")
      .test(
        "length",
        "Must be at least 2 letters",
        (val) => (val || "").replace(/[\s+]/g, "").length >= 2
      ),
  }),
  delivery_postal_code: Yup.string().when("custom_address", {
    is: true,
    then: Yup.string()
      .required("Postal Code is a required field!")
      .test(
        "length",
        "Must be at least 2 letters",
        (val) => (val || "").replace(/[\s+]/g, "").length >= 2
      ),
  }),
  delivery_city: Yup.string().when("custom_address", {
    is: true,
    then: Yup.string()
      .required("City/Suburb is a required field!")
      .matches(
        /(^[a-zA-Z\u00C0-\u024F -']+$)/,
        "Only alphabet characters are allowed"
      )
      .test(
        "length",
        "Must be at least 2 letters",
        (val) => (val || "").replace(/[\s+]/g, "").length >= 2
      ),
  }),
  delivery_country: Yup.string()
    .required("Country is a required field!")
    .test(
      "length",
      "Must be at least 2 letters",
      (val) => (val || "").replace(/[\s+]/g, "").length >= 2
    ),
});

export const OrderkeySchema = Yup.object({
  item_code: Yup.string().required("Please select a key"),
  delivery_description: Yup.string().when("new_key", {
    is: true,
    then: Yup.string().required("Please provide a reason for the change"),
  }),
});
