import { LinearProgress, Dialog, DialogContent } from "@mui/material";

interface ILoaderProps {
  title?: string;
  onClose?: () => void;
  isOpen: boolean;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
}

export const Loader = ({
  title = "Loading...",
  onClose,
  isOpen,
  maxWidth = "xs",
  ...other
}: ILoaderProps) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={maxWidth}
      {...other}
    >
      <DialogContent
        className="p-0 pt-10 mx-5"
        style={{ marginTop: "20px", marginBottom: "20px", padding: 0 }}
      >
        <div>
          <div className="mb-2">{title}</div>
          <LinearProgress color="inherit" sx={{ color: "#1F8290" }} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
