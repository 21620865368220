import config from "react-global-configuration";
import { IPayload } from "../types";

export const getCountries = () =>
  fetch(`${config.get("ApiUrl").Rest}/countries`, {
    mode: "cors",
    credentials: "include",
  });

export const createSalesOrder = (data: IPayload) =>
  fetch(`${config.get("ApiUrl").Rest}/order`, {
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getKeys = () =>
  fetch(`${config.get("ApiUrl").Rest}/products`, {
    mode: "cors",
    credentials: "include",
  });
