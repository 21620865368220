/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Lookup, {
  AddressResolveResponse,
  AddressRetrieveResponse,
} from "../ui/helpers/loqate";
import { Dispatch, SetStateAction } from "react";
import config from "react-global-configuration";
import { ICountryResponse, IKeysResponse } from "./OrderDetails/types";
import { countryToAlpha2 } from "country-to-iso";

export const getOptionsData = (data: AddressResolveResponse[]) =>
  data.map(({ Id, Text, Description }) => ({
    value: Id,
    label: Description + " " + Text,
  }));

export const filterAddresses = (
  filter: string,
  limit: number,
  country: string,
  setCurrentAddresses: Dispatch<SetStateAction<AddressResolveResponse[]>>
) => {
  const lookup = new Lookup(config.get("loqate").api_key, filter);
  lookup
    .limit(limit)
    .inLanguage("ENG")
    .inCountry(country)
    .find(filter)
    .get()
    .then((results) => {
      setCurrentAddresses(results);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const setAddressData = (
  value: string,
  limit: number,
  country: string,
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void,
  currentAddresses: AddressResolveResponse[]
) => {
  const filter = currentAddresses.find(
    (address) => address.Description + " " + address.Text === value
  );
  if (filter !== undefined) {
    const lookup = new Lookup(config.get("loqate").api_key, filter.Id);
    lookup
      .limit(limit)
      .inLanguage("ENG")
      .inCountry(country)
      .retrieve(filter)
      .then((result: AddressRetrieveResponse) => {
        setFieldValue("delivery_address_1", result.Line1);
        setFieldValue("delivery_country_code", result.CountryIso2);
        setFieldValue("delivery_city", result.City);
        setFieldValue("delivery_postal_code", result.PostalCode);
        setFieldValue("delivery_state", result.ProvinceName || result.City);
        if (result.Line2 !== "") {
          setFieldValue("delivery_address_2", result.Line2);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const modifyCountries = (data: ICountryResponse[]) =>
  data.map((country) => {
    return {
      value: countryToAlpha2(country.Country),
      label: country.Country,
    };
  });

export const modifyKeys = (data: IKeysResponse[]) => {
  return data.map((key) => {
    return {
      value: key.Code,
      label: key.ProductName,
      isDefault: key.IsDefault,
    };
  });
};
