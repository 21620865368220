/* eslint-disable react/no-unknown-property */
import {
  buttonMenu,
  defaultBtnStyle,
  descriptionStyle,
  headerStyle,
  mainText,
} from "./OrderDetails.style";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { OrderFormSchema } from "../../schemas";
import { MappedInput } from "../../ui/components/Input";
import { CustomButton } from "../../ui/components/Button";
import { Autocomplete, IOptionItem } from "../../ui/components/Autocomplete";
import { ICountryResponse, IPayload } from "./types";
import { CustomSwitch } from "../../ui/components/Switch";
import { AddressResolveResponse } from "../../ui/helpers/loqate";
import { getOptionsData, modifyCountries } from "../helpers";
import { Footer } from "../Footer/Footer";
import { getCountries } from "./_api";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useFetch } from "../../hooks/fetch.hook";
import { CountryAutocomplete } from "./CountryAutocomplete";

interface IOrderFormProps {
  handleNextStep: () => void;
  data: IPayload;
  setData: Dispatch<SetStateAction<IPayload>>;
  isCustomAddress: boolean;
  setCustomAddress: Dispatch<SetStateAction<boolean>>;
  isCountrySelected: boolean;
  setCountrySelected: Dispatch<SetStateAction<boolean>>;
}

export interface IFormikValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  delivery_address_full: string;
  delivery_address_1: string;
  delivery_address_2: string;
  delivery_city: string;
  delivery_state: string;
  delivery_postal_code: string;
  delivery_country: string;
  delivery_country_code: string;
  delivery_address_id: string;
  custom_address: boolean;
}

export const OrderForm = ({
  handleNextStep,
  data,
  setData,
  isCustomAddress,
  setCustomAddress,
  isCountrySelected,
  setCountrySelected,
}: IOrderFormProps) => {
  const { request } = useFetch();
  const [countries, setCountries] = useState<IOptionItem[]>([]);
  const [currentAddresses, setCurrentAddresses] = useState<
    AddressResolveResponse[]
  >([]);
  const [countryCode, setCountryCode] = useState<string>(
    data.delivery_country_code
  );

  const initialValues: IFormikValues = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    phone: data.phone,
    delivery_address_full: data.delivery_address_full,
    delivery_address_id: data.delivery_address_id,
    delivery_address_1: data.delivery_address_1,
    delivery_address_2: data.delivery_address_2,
    delivery_city: data.delivery_city,
    delivery_state: data.delivery_state,
    delivery_postal_code: data.delivery_postal_code,
    delivery_country: data.delivery_country,
    delivery_country_code: data.delivery_country_code,
    custom_address: isCustomAddress,
  };

  const handleSubmit = (values: IFormikValues) => {
    setData((prev) => ({
      ...prev,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      delivery_address_full: values.delivery_address_full,
      delivery_address_id: values.delivery_address_id,
      delivery_address_1: values.delivery_address_1,
      delivery_address_2: values.delivery_address_2,
      delivery_city: values.delivery_city,
      delivery_state: values.delivery_state || values.delivery_city,
      delivery_postal_code: values.delivery_postal_code,
      delivery_country: values.delivery_country,
      delivery_country_code: values.delivery_country_code,
    }));
    handleNextStep();
  };

  const slideHandler = (values: IFormikValues) => {
    if (isCustomAddress) {
      setCustomAddress(false);
      values.custom_address = false;
    } else {
      setCustomAddress(true);
      values.custom_address = true;
    }
  };

  useEffect(() => {
    void request(getCountries).then((countryResponse: ICountryResponse[]) => {
      if (!countryResponse) return;
      setCountries(modifyCountries(countryResponse));
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (countryCode !== "") {
      setCountrySelected(true);
    } else {
      setCountrySelected(false);
    }
  }, [countryCode, setCountrySelected]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={OrderFormSchema}
        onSubmit={handleSubmit}
      >
        {({
          setFieldValue,
          values,
          errors,
          touched,
        }: FormikProps<IFormikValues>) => (
          <Form>
            <div className="px-5">
              <h1 css={mainText} className="text-center">
                We’re happy to be able to offer you a YubiKey{" "}
                <br className="d-none d-md-block " /> absolutely free of charge
                to secure your Immutable Okta account
              </h1>
              <section className="mb-5">
                <p css={headerStyle}>Your Details</p>
                <div className="d-flex-column d-md-flex w-100 justify-content-between">
                  <MappedInput
                    label="First Name"
                    name="first_name"
                    classes="mr-5"
                    touched={touched["first_name"]}
                  />
                  <MappedInput
                    label="Last Name"
                    name="last_name"
                    touched={touched["last_name"]}
                  />
                </div>
                <MappedInput
                  label="Email Address"
                  name="email"
                  touched={touched["email"]}
                />
                <MappedInput
                  label="Phone Number at Delivery Point"
                  name="phone"
                  touched={touched["phone"]}
                />
              </section>
              <section>
                <p css={headerStyle}>Delivery Details</p>
                <p css={descriptionStyle}>
                  Where do you want your order delivered to?*
                </p>

                {isCustomAddress ? (
                  <>
                    <MappedInput label="Country" name="delivery_country" />
                    <MappedInput label="Address" name="delivery_address_1" />
                    <MappedInput label="Address 2" name="delivery_address_2" />
                    <MappedInput label="City" name="delivery_city" />
                    <MappedInput label="State" name="delivery_state" />
                    <MappedInput label="Postcode" name="delivery_postal_code" />
                  </>
                ) : (
                  <>
                    <CountryAutocomplete
                      countries={countries}
                      isCustomAddress={isCustomAddress}
                      countryCode={countryCode}
                      setCountryCode={setCountryCode}
                    />

                    {isCountrySelected && (
                      <div className="my-4">
                        <Autocomplete
                          options={getOptionsData(currentAddresses)}
                          defaultValue={{
                            value: values.delivery_address_id,
                            label: values.delivery_address_full,
                          }}
                          name_label="delivery_address_full"
                          name_value="delivery_address_id"
                          placeholder="Please start typing..."
                          title="Address"
                          disabled={isCustomAddress ? isCustomAddress : false}
                          setFieldValue={setFieldValue}
                          isAddressSearch={true}
                          currentAddresses={currentAddresses}
                          setCurrentAddress={setCurrentAddresses}
                          countryCode={countryCode}
                          setCountryCode={setCountryCode}
                        />
                        {errors.delivery_address_full ? (
                          <span className="text-danger mt-1">
                            {errors.delivery_address_full}
                          </span>
                        ) : null}
                      </div>
                    )}
                  </>
                )}
                <div
                  className="d-flex justify-content-between align-items-center"
                  css={buttonMenu}
                >
                  <CustomSwitch
                    onClick={() => slideHandler(values)}
                    checked={isCustomAddress}
                    id="address_slider"
                  />
                  <CustomButton
                    label="Next"
                    buttonStyle={defaultBtnStyle}
                    type="submit"
                  />
                </div>
              </section>
            </div>
          </Form>
        )}
      </Formik>
      <Footer />
    </>
  );
};
