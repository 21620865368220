/* eslint-disable react/no-unknown-property */
import { Auth } from "aws-amplify";
import { CustomButton } from "../../ui/components/Button";
import Logo from "../../ui/static/Logo.svg";
import { logoTitle } from "../Header/Header.styles";
import { loginBtn, loginText, loginWrapper } from "./Login.style";

export const LoginPage = () => {
  return (
    <>
      <div className="login-background w-100 h-100 d-flex justify-content-center align-items-start">
        <div css={loginWrapper}>
          <div className="d-flex-column justify-content-center align-items-center mb-4">
            <img width="80" height="auto" src={Logo} alt="Logo" />
            <h1 css={logoTitle} className="ml-2">
              Immutable
            </h1>
          </div>
          <CustomButton
            label="Sign in with Okta"
            buttonStyle={loginBtn}
            onClick={() => Auth.federatedSignIn({ provider: "Okta" })}
          />
          <p css={loginText} className="mt-3 mb-0">
            Please sign in with your OKTA account{" "}
            <br className="d-none d-lg-block " />
            to get started with ordering your Yubikey!
          </p>
        </div>
      </div>
    </>
  );
};
