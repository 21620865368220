export const descriptionOptions = [
  {
    value: "I need a different form factor",
    label: "I need a different form factor",
  },
  {
    value: "I lost my key",
    label: "I lost my key",
  },
];
