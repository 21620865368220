import {
  Autocomplete as MuiAutocomplete,
  Input as MuiInput,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { AddressResolveResponse } from "../helpers/loqate";
import uniqBy from "lodash/uniqBy";
import { filterAddresses, setAddressData } from "../../components/helpers";

export interface IOptionItem {
  value: string | number | null;
  label: string;
}

interface IAutocompleteProps {
  id?: string;
  label?: string;
  title?: string;
  name_value: string;
  name_label: string;
  placeholder?: string;
  loading?: boolean;
  defaultValue?: IOptionItem;
  options: IOptionItem[];
  disabled?: boolean;
  isAddressSearch: boolean;
  currentAddresses?: AddressResolveResponse[];
  setCurrentAddress?: Dispatch<SetStateAction<AddressResolveResponse[]>>;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
  countryCode: string;
  setCountryCode: Dispatch<SetStateAction<string>>;
  customChangeHandler?: () => void;
}

export const Autocomplete = ({
  options = [],
  placeholder = "Select option",
  disabled,
  label = "label",
  setFieldValue,
  currentAddresses,
  setCurrentAddress,
  defaultValue,
  name_value,
  name_label,
  isAddressSearch,
  countryCode,
  setCountryCode,
  customChangeHandler,
  title,
  ...other
}: IAutocompleteProps) => {
  const sortHelper = function (previousItem: unknown, nextItem: unknown) {
    if (previousItem[label] > nextItem[label]) return 1;
    if (previousItem[label] < nextItem[label]) return -1;
    return 0;
  };
  return (
    <div>
      <MuiAutocomplete
        loading
        id={name_label}
        options={uniqBy(options, label).sort(sortHelper)}
        value={defaultValue}
        disabled={disabled}
        getOptionLabel={(option: IOptionItem) => option.label || ""}
        isOptionEqualToValue={(option: IOptionItem, value: IOptionItem) =>
          option.label === value.label
        }
        onChange={(_, value) => {
          customChangeHandler && customChangeHandler()
          if (isAddressSearch) {
            setAddressData(
              value?.label,
              10,
              countryCode,
              setFieldValue,
              currentAddresses
            );
          } else {
            setCountryCode(value?.value);
          }
          setFieldValue(name_label, value?.label);
          setFieldValue(name_value, value?.value);
        }}
        renderInput={(params) => (
          <div
            className="pt-2 px-4 pb-0 rounded bg-white"
            style={{
              borderBottom: "1px solid #255915",
            }}
          >
            <div className="text-muted">{title}</div>
            <MuiInput
              id={params.id}
              name={name_label}
              disabled={disabled}
              placeholder={placeholder}
              fullWidth={true}
              inputProps={params.inputProps}
              endAdornment={params.InputProps.endAdornment}
              ref={params.InputProps.ref}
              disableUnderline
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                if (event.target.value.length > 2) {
                  setFieldValue(name_label, event.target.value);
                  setFieldValue(name_value, event.target.value);
                  if (isAddressSearch) {
                    filterAddresses(
                      event.target.value,
                      10,
                      countryCode,
                      setCurrentAddress
                    );
                  }
                }
              }}
            />
          </div>
        )}
        {...other}
      />
    </div>
  );
};
