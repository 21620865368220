/* eslint-disable react/no-unknown-property */
import {
  confirmStyle,
  defaultBtnStyle,
  headerStyle,
  mainText,
} from "./OrderDetails.style";
import { useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useFetch } from "../../hooks/fetch.hook";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../ui/components/Button";
import { Loader } from "../../ui/components/Loader";
import { fetchResponse, IPayload } from "./types";
import { createSalesOrder } from "./_api";
import { warning } from "../../helpers/toasts";

interface IOrderConfirmProps {
  data: IPayload;
  handleBack: () => void;
}

export const OrderConfirm = ({ data, handleBack }: IOrderConfirmProps) => {
  const navigate = useNavigate();
  const { request } = useFetch();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      delivery_address_1: data.delivery_address_1,
      delivery_address_2: data.delivery_address_2,
      delivery_city: data.delivery_city,
      delivery_state: data.delivery_state,
      delivery_postal_code: data.delivery_postal_code,
      delivery_country: data.delivery_country,
      item_code: data.item_code,
      delivery_description: data.delivery_description,
    };
    request(createSalesOrder, payload)
      .then((response: fetchResponse) => {
        if (!response) {
          warning("Oops, something has gone wrong, please try again!");
          return;
        } else {
          if (response.message === "Missing or invalid fields") {
            warning(
              "Please make sure that you completed all the required fields!"
            );
          } else {
            navigate("/tracking");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loader isOpen={loading} title="Processing your order..." />}

      <h1 css={mainText} className="text-center mb-5 px-4">
        Just one last thing - please make sure the details below are
        <br className="d-none d-md-block" /> correct. We don’t want your key
        getting lost in the mail
      </h1>
      <section className="text-center">
        <p css={headerStyle}>Are These Details 100% Correct?</p>
        <div
          css={confirmStyle}
          className="d-flex-col justify-content-center align-items-center"
        >
          <p className="my-2">{data.item_name}</p>
          <p className="mb-3">{`${data.first_name} ${data.last_name}`}</p>
          <div className="mb-3">
            <p className="my-0">{`${data.delivery_address_1}`}</p>
            {data.delivery_address_2 !== "" && (
              <p className="my-0">{`${data.delivery_address_2}`}</p>
            )}
            <p className="my-0">{`${data.delivery_city}, ${data.delivery_state} ${data.delivery_postal_code}`}</p>
            <p className="my-0">{data.delivery_country}</p>
          </div>
          <p className="mb-3">Phone: {data.phone}</p>
          <p className="mb-3">Email: {data.email}</p>
        </div>
        <div
          className="d-flex justify-content-center align-items-center pb-5"
          style={{ marginTop: "100px" }}
        >
          <CustomButton
            label="No"
            onClick={handleBack}
            buttonStyle={defaultBtnStyle}
            classes="mr-5"
          />
          <CustomButton
            label="Yes"
            onClick={handleSubmit}
            buttonStyle={defaultBtnStyle}
          />
        </div>
      </section>
    </>
  );
};
