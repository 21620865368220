/* eslint-disable @typescript-eslint/restrict-plus-operands */
interface metaObject {
  touched: boolean | undefined;
  error?: string | undefined;
}

export function getTip(meta: metaObject, tip: string | undefined) {
  if (meta.touched && meta.error) {
    return <span className="text-danger mt-1">{meta.error}</span>;
  }
  if (tip) {
    return <span className="text-muted mt-1">{tip}</span>;
  }
}

function isValidDate(d: Date) {
  return d instanceof Date;
}

export function formatDate(date: Date | string | number) {
  const d = new Date(date);
  if (isValidDate(d)) {
    const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    const month = d.getMonth() + 1;
    const monthWithNull = month < 10 ? "0" + month : month;
    return day + "/" + monthWithNull + "/" + d.getFullYear();
  }
  console.error("Not a valid Date");
}

export const getCookie = (cname: string) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let cookie = ca[i];
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie =
      name +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;max-age=0;path=/;domain=.dev.immutable.trustpanda.com";
    document.cookie =
      name +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;max-age=0;path=/;domain=.immutable.trustpanda.com";
    document.cookie =
      name +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;max-age=0;path=/;domain=.trustpanda.com";
  }
};

export const isCookiePresent = (cookieName: string) =>
  getCookie(cookieName) ? true : false;
