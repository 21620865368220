/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { toast } from "react-toastify";

const COMMON_CLASSNAME = "rounded";

export function info(message: string, props?: any) {
  return toast.info(message, {
    ...props,
    className: `bg-info ${COMMON_CLASSNAME}`,
  });
}

export function successDark(message: string, props?: any) {
  return toast.success(message, {
    ...props,
    className: `bg-dark ${COMMON_CLASSNAME}`,
  });
}

export function errorDark(message: string, props?: any) {
  return toast.error(message, {
    ...props,
    className: `bg-dark ${COMMON_CLASSNAME}`,
  });
}

export function error(message: string, props?: any) {
  return toast.error(message, {
    ...props,
    className: `bg-danger ${COMMON_CLASSNAME}`,
  });
}

export function warning(message: string, props?: any) {
  return toast.warning(message, {
    ...props,
    className: `bg-warning ${COMMON_CLASSNAME}`,
  });
}

export function dark(message: string, props?: any) {
  return toast.warning(message, {
    ...props,
    className: `bg-dark ${COMMON_CLASSNAME}`,
  });
}

export function light(message: string, props?: any) {
  return toast.warning(message, {
    ...props,
    className: `bg-light ${COMMON_CLASSNAME}`,
  });
}
