import { css } from "@emotion/react";

export const mainText = css({
  color: "black",
  fontSize: "1.6rem",
  fontWeight: "600",
  marginBottom: "39px",
  lineHeight: "36px",
  marginTop: "60px",
});

export const headerStyle = css({
  fontSize: "1.3rem",
  fontWeight: "700",
  marginBottom: "14px",
});

export const descriptionStyle = css({
  fontSize: "1.2rem",
  color: "#6C757D",
  opacity: ".8",
});

export const confirmStyle = css({
  fontSize: "1.2rem",
  fontWeight: 700,
  marginBottom: 0,
  color: "#6C757D",
  opacity: ".8",
});

export const fieldsWrapper = css({ marginBottom: "47px" });

export const defaultBtnStyle = css({
  backgroundColor: "#000000",
  border: "none",
  "&:hover, &:focus": {
    backgroundColor: "#1F8290",
    outline: "none",
    border: "none",
  },
  color: "white",
  boxShadow: "0px 1px rgba(0, 0, 0, 0.2)",
  padding: "8px 40px",
  letterSpacing: "1.25px",
  fontSize: "16px",
  fontWeight: "400",
  outline: "none",
  cursor: "pointer",
  borderRadius: "4px",
});

export const buttonMenu = css({
  marginTop: "30px",
  marginBottom: "100px",
});

export const signOutBtnStyle = css({
  backgroundColor: "#1F8290",
  textAlign: "center",
  border: "none",
  fontSize: "1.3rem",
  color: "white",
  boxShadow: "0px 1px rgba(0, 0, 0, 0.2)",
  borderRadius: "4px",
  cursor: "pointer",
  "&:hover, &:focus": {
    backgroundColor: "#59ACC9",
    outline: "none",
    border: "none",
  },
});

export const trackingTextStyle = css({
  fontSize: "1.2rem",
});

export const trackingBtnStyle = css({
  backgroundColor: "#000000",
  border: "none",
  "&:hover, &:focus": {
    backgroundColor: "#1F8290",
    outline: "none",
    border: "none",
  },
  color: "white",
  boxShadow: "0px 1px rgba(0, 0, 0, 0.2)",
  padding: "8px 40px",
  letterSpacing: "1.25px",
  fontSize: "16px",
  fontWeight: "400",
  outline: "none",
  borderRadius: "4px",
  cursor: "pointer",
});
