{
  "AD": "AD",
  "ANDORRA": "AD",
  "UNITEDARABEMIRATES": "AE",
  "دولةالإماراتالعربيةالمتحدة": "AE",
  "UAE": "AE",
  "AE": "AE",
  "ARE": "AE",
  "AFGHANISTAN": "AF",
  "افغانستان": "AF",
  "AFGANISTAN": "AF",
  "AF": "AF",
  "AFG": "AF",
  "ANTIGUABARBUDA": "AG",
  "ANTIGUAANDBARBUDA": "AG",
  "AG": "AG",
  "ATG": "AG",
  "ANGUILLA": "AI",
  "AI": "AI",
  "AIA": "AI",
  "ALBANIA": "AL",
  "SHQIPERIA": "AL",
  "SHQIPERI": "AL",
  "SHQIPNIA": "AL",
  "AL": "AL",
  "ALB": "AL",
  "ARMENIA": "AM",
  "ՀԱՅԱՍՏԱՆ": "AM",
  "HAYASTAN": "AM",
  "REPUBLICARMENIA": "AM",
  "REPUBLICOFARMENIA": "AM",
  "ՀԱՅԱՍՏԱՆԻՀԱՆՐԱՊԵՏՈՒԹՅՈՒՆ": "AM",
  "AM": "AM",
  "ARM": "AM",
  "ANGOLA": "AO",
  "REPUBLICADEANGOLA": "AO",
  "REPUBLIKADEANGOLA": "AO",
  "AO": "AO",
  "AGO": "AO",
  "AQ": "AQ",
  "ATA": "AQ",
  "ANTARCTICA": "AQ",
  "ARGENTINA": "AR",
  "ARGENTINEREPUBLIC": "AR",
  "REPUBLICAARGENTINA": "AR",
  "AR": "AR",
  "ARG": "AR",
  "AMERICANSAMOA": "AS",
  "AMERIKASAMOA": "AS",
  "AMELIKASAMOA": "AS",
  "SAMOAAMELIKA": "AS",
  "AS": "AS",
  "ASM": "AS",
  "AUSTRIA": "AT",
  "OSTERREICH": "AT",
  "OESTERREICH": "AT",
  "AT": "AT",
  "AUT": "AT",
  "AUSTRALIA": "AU",
  "AU": "AU",
  "AUS": "AU",
  "ARUBA": "AW",
  "AW": "AW",
  "ABW": "AW",
  "AX": "AX",
  "ALA": "AX",
  "ALANDISLANDS": "AX",
  "AZERBAIJAN": "AZ",
  "AZƏRBAYCAN": "AZ",
  "REPUBLICAZERBAIJAN": "AZ",
  "REPUBLICOFAZERBAIJAN": "AZ",
  "AZƏRBAYCANRESPUBLIKASI": "AZ",
  "AZ": "AZ",
  "AZE": "AZ",
  "AZERBAIJANREPUBLIC": "AZ",
  "AZERBAIJANREPUBLICOF": "AZ",
  "BOSNIAHERZEGOVINA": "BA",
  "BOSNIAANDHERZEGOVINA": "BA",
  "BOSNAIHERCEGOVINA": "BA",
  "BOSNAIHERTSEGOVINA": "BA",
  "BA": "BA",
  "BIH": "BA",
  "BARBADOS": "BB",
  "BB": "BB",
  "BRB": "BB",
  "BANGLADESH": "BD",
  "বাংলাদেশ": "BD",
  "PEOPLESREPUBLICBANGLADESH": "BD",
  "PEOPLESREPUBLICOFBANGLADESH": "BD",
  "GONOPROJATONTRIBANGLADESH": "BD",
  "BD": "BD",
  "BGD": "BD",
  "BELGIUM": "BE",
  "BELGIE": "BE",
  "BELGIEN": "BE",
  "BELGIQUE": "BE",
  "KINGDOMBELGIUM": "BE",
  "KINGDOMOFBELGIUM": "BE",
  "KONINKRIJKBELGIE": "BE",
  "ROYAUMEDEBELGIQUE": "BE",
  "KONIGREICHBELGIEN": "BE",
  "BE": "BE",
  "BEL": "BE",
  "BURKINAFASO": "BF",
  "BF": "BF",
  "BFA": "BF",
  "BULGARIA": "BG",
  "BLGARIYA": "BG",
  "REPUBLICBULGARIA": "BG",
  "REPUBLICOFBULGARIA": "BG",
  "REPUBLIKABLGARIYA": "BG",
  "BG": "BG",
  "BGR": "BG",
  "BAHRAIN": "BH",
  "‏البحرين": "BH",
  "KINGDOMBAHRAIN": "BH",
  "KINGDOMOFBAHRAIN": "BH",
  "MAMLAKATALBAHRAYN": "BH",
  "BH": "BH",
  "BHR": "BH",
  "BURUNDI": "BI",
  "REPUBLICBURUNDI": "BI",
  "REPUBLICOFBURUNDI": "BI",
  "REPUBLIKAYUBURUNDI": "BI",
  "REPUBLIQUEDUBURUNDI": "BI",
  "BI": "BI",
  "BDI": "BI",
  "BENIN": "BJ",
  "REPUBLICBENIN": "BJ",
  "REPUBLICOFBENIN": "BJ",
  "REPUBLIQUEDUBENIN": "BJ",
  "BJ": "BJ",
  "BEN": "BJ",
  "BL": "BL",
  "BLM": "BL",
  "SAINTBARTHELEMY": "BL",
  "BERMUDA": "BM",
  "ISLANDSBERMUDA": "BM",
  "THEISLANDSOFBERMUDA": "BM",
  "BERMUDAS": "BM",
  "THEBERMUDAS": "BM",
  "SOMERSISLES": "BM",
  "BM": "BM",
  "BMU": "BM",
  "BRUNEIDARUSSALAM": "BN",
  "BRUNEI": "BN",
  "NEGARABRUNEIDARUSSALAM": "BN",
  "NATIONBRUNEI": "BN",
  "NATIONOFBRUNEI": "BN",
  "ABODEPEACE": "BN",
  "THEABODEOFPEACE": "BN",
  "BN": "BN",
  "BRN": "BN",
  "BOLIVIAPLURINATIONALSTATE": "BO",
  "BOLIVIAPLURINATIONALSTATEOF": "BO",
  "BOLIVIA": "BO",
  "BULIWYA": "BO",
  "WULIWYA": "BO",
  "PLURINATIONALSTATEBOLIVIA": "BO",
  "PLURINATIONALSTATEOFBOLIVIA": "BO",
  "ESTADOPLURINACIONALDEBOLIVIA": "BO",
  "BULIWYAMAMALLAQTA": "BO",
  "WULIWYASUYU": "BO",
  "TETAVOLIVIA": "BO",
  "BO": "BO",
  "BOL": "BO",
  "BQ": "BQ",
  "BES": "BQ",
  "BONAIRESINTEUSTATIUSSABA": "BQ",
  "BONAIRESINTEUSTATIUSANDSABA": "BQ",
  "BONAIRESAINTEUSTATIUSSABA": "BQ",
  "BONAIRESAINTEUSTATIUSANDSABA": "BQ",
  "BRAZIL": "BR",
  "BRASIL": "BR",
  "FEDERATIVEREPUBLICBRAZIL": "BR",
  "FEDERATIVEREPUBLICOFBRAZIL": "BR",
  "REPUBLICAFEDERATIVADOBRASIL": "BR",
  "BR": "BR",
  "BRA": "BR",
  "BAHAMAS": "BS",
  "THEBAHAMAS": "BS",
  "COMMONWEALTHBAHAMAS": "BS",
  "COMMONWEALTHOFTHEBAHAMAS": "BS",
  "BS": "BS",
  "BHS": "BS",
  "BHUTAN": "BT",
  "ʼBRUGYUL": "BT",
  "KINGDOMBHUTAN": "BT",
  "KINGDOMOFBHUTAN": "BT",
  "BT": "BT",
  "BTN": "BT",
  "BV": "BV",
  "BVT": "BV",
  "BOUVETISLAND": "BV",
  "BOTSWANA": "BW",
  "REPUBLICBOTSWANA": "BW",
  "REPUBLICOFBOTSWANA": "BW",
  "LEFATSHELABOTSWANA": "BW",
  "BW": "BW",
  "BWA": "BW",
  "BELARUS": "BY",
  "BELARÚS": "BY",
  "BIELARUS": "BY",
  "REPUBLICBELARUS": "BY",
  "REPUBLICOFBELARUS": "BY",
  "BELORUSSIYA": "BY",
  "RESPUBLIKABELARUS": "BY",
  "RESPUBLIKABELARUS’": "BY",
  "BY": "BY",
  "BLR": "BY",
  "BELIZE": "BZ",
  "BZ": "BZ",
  "BLZ": "BZ",
  "CANADA": "CA",
  "CA": "CA",
  "CAN": "CA",
  "COCOSKEELINGISLANDS": "CC",
  "TERRITORYCOCOSKEELINGISLANDS": "CC",
  "TERRITORYOFTHECOCOSKEELINGISLANDS": "CC",
  "KEELINGISLANDS": "CC",
  "CC": "CC",
  "CCK": "CC",
  "CONGODEMOCRATICREPUBLIC": "CD",
  "CONGODEMOCRATICREPUBLICOFTHE": "CD",
  "DEMOCRATICREPUBLICCONGO": "CD",
  "DEMOCRATICREPUBLICOFTHECONGO": "CD",
  "REPUBLIQUEDEMOCRATIQUEDUCONGO": "CD",
  "DRCONGO": "CD",
  "CONGOKINSHASA": "CD",
  "DRC": "CD",
  "CD": "CD",
  "COD": "CD",
  "CONGOTHEDEMOCRATICREPUBLICOFTHE": "CD",
  "CENTRALAFRICANREPUBLIC": "CF",
  "KODOROSESETIBEAFRIKA": "CF",
  "REPUBLIQUECENTRAFRICAINE": "CF",
  "CF": "CF",
  "CAF": "CF",
  "CONGO": "CG",
  "REPUBLICCONGO": "CG",
  "REPUBLICOFTHECONGO": "CG",
  "REPUBLIQUEDUCONGO": "CG",
  "CONGOBRAZZAVILLE": "CG",
  "CG": "CG",
  "COG": "CG",
  "SWITZERLAND": "CH",
  "SCHWEIZ": "CH",
  "SWISSCONFEDERATION": "CH",
  "SUISSE": "CH",
  "SVIZZERA": "CH",
  "SVIZRA": "CH",
  "CH": "CH",
  "CHE": "CH",
  "COTEDIVOIRE": "CI",
  "IVORYCOAST": "CI",
  "REPUBLICCOTEDIVOIRE": "CI",
  "REPUBLICOFCOTEDIVOIRE": "CI",
  "REPUBLIQUEDECOTEDIVOIRE": "CI",
  "CI": "CI",
  "CIV": "CI",
  "COOKISLANDS": "CK",
  "KUKIAIRANI": "CK",
  "CK": "CK",
  "COK": "CK",
  "CHILE": "CL",
  "REPUBLICCHILE": "CL",
  "REPUBLICOFCHILE": "CL",
  "REPUBLICADECHILE": "CL",
  "CL": "CL",
  "CHL": "CL",
  "CAMEROON": "CM",
  "REPUBLICCAMEROON": "CM",
  "REPUBLICOFCAMEROON": "CM",
  "REPUBLIQUEDUCAMEROUN": "CM",
  "CM": "CM",
  "CMR": "CM",
  "CHINA": "CN",
  "中国": "CN",
  "ZHONGGUO": "CN",
  "ZHONGHUA": "CN",
  "PEOPLESREPUBLICCHINA": "CN",
  "PEOPLESREPUBLICOFCHINA": "CN",
  "中华人民共和国": "CN",
  "ZHONGHUARENMINGONGHEGUO": "CN",
  "CN": "CN",
  "CHN": "CN",
  "CHINAPEOPLESREPUBLIC": "CN",
  "CHINAPEOPLESREPUBLICOF": "CN",
  "COLOMBIA": "CO",
  "REPUBLICCOLOMBIA": "CO",
  "REPUBLICOFCOLOMBIA": "CO",
  "REPUBLICADECOLOMBIA": "CO",
  "CO": "CO",
  "COL": "CO",
  "COSTARICA": "CR",
  "REPUBLICCOSTARICA": "CR",
  "REPUBLICOFCOSTARICA": "CR",
  "REPUBLICADECOSTARICA": "CR",
  "CR": "CR",
  "CRI": "CR",
  "CUBA": "CU",
  "REPUBLICCUBA": "CU",
  "REPUBLICOFCUBA": "CU",
  "REPUBLICADECUBA": "CU",
  "CU": "CU",
  "CUB": "CU",
  "CABOVERDE": "CV",
  "CAPEVERDE": "CV",
  "REPUBLICCABOVERDE": "CV",
  "REPUBLICOFCABOVERDE": "CV",
  "REPUBLICADECABOVERDE": "CV",
  "CV": "CV",
  "CPV": "CV",
  "CW": "CW",
  "CUW": "CW",
  "CURACAO": "CW",
  "CHRISTMASISLAND": "CX",
  "TERRITORYCHRISTMASISLAND": "CX",
  "TERRITORYOFCHRISTMASISLAND": "CX",
  "CX": "CX",
  "CXR": "CX",
  "CYPRUS": "CY",
  "ΚΎΠΡΟΣ": "CY",
  "KYPROS": "CY",
  "KIBRIS": "CY",
  "REPUBLICCYPRUS": "CY",
  "REPUBLICOFCYPRUS": "CY",
  "ΚΥΠΡΙΑΚΉΔΗΜΟΚΡΑΤΊΑ": "CY",
  "KIBRISCUMHURIYETI": "CY",
  "CY": "CY",
  "CYP": "CY",
  "CZECHIA": "CZ",
  "CZECHREPUBLIC": "CZ",
  "CESKAREPUBLIKA": "CZ",
  "CESKO": "CZ",
  "CZ": "CZ",
  "CZE": "CZ",
  "GERMANY": "DE",
  "DEUTSCHLAND": "DE",
  "FEDERALREPUBLICGERMANY": "DE",
  "FEDERALREPUBLICOFGERMANY": "DE",
  "BUNDESREPUBLIKDEUTSCHLAND": "DE",
  "DE": "DE",
  "DEU": "DE",
  "DJIBOUTI": "DJ",
  "JABUUTI": "DJ",
  "GABUUTI": "DJ",
  "REPUBLICDJIBOUTI": "DJ",
  "REPUBLICOFDJIBOUTI": "DJ",
  "REPUBLIQUEDEDJIBOUTI": "DJ",
  "GABUUTIHUMMUUNO": "DJ",
  "JAMHUURIYADDAJABUUTI": "DJ",
  "DJ": "DJ",
  "DJI": "DJ",
  "DENMARK": "DK",
  "DANMARK": "DK",
  "KINGDOMDENMARK": "DK",
  "KINGDOMOFDENMARK": "DK",
  "KONGERIGETDANMARK": "DK",
  "DK": "DK",
  "DNK": "DK",
  "DOMINICA": "DM",
  "DOMINIQUE": "DM",
  "WAI‘TUKUBULI": "DM",
  "COMMONWEALTHDOMINICA": "DM",
  "COMMONWEALTHOFDOMINICA": "DM",
  "DM": "DM",
  "DMA": "DM",
  "DOMINICANREPUBLIC": "DO",
  "REPUBLICADOMINICANA": "DO",
  "DO": "DO",
  "DOM": "DO",
  "ALGERIA": "DZ",
  "الجزائر": "DZ",
  "DZAYER": "DZ",
  "ALGERIE": "DZ",
  "DZ": "DZ",
  "DZA": "DZ",
  "ECUADOR": "EC",
  "REPUBLICECUADOR": "EC",
  "REPUBLICOFECUADOR": "EC",
  "REPUBLICADELECUADOR": "EC",
  "EC": "EC",
  "ECU": "EC",
  "ESTONIA": "EE",
  "EESTI": "EE",
  "REPUBLICESTONIA": "EE",
  "REPUBLICOFESTONIA": "EE",
  "EESTIVABARIIK": "EE",
  "EE": "EE",
  "EST": "EE",
  "EGYPT": "EG",
  "مصر‎": "EG",
  "ARABREPUBLICEGYPT": "EG",
  "ARABREPUBLICOFEGYPT": "EG",
  "EG": "EG",
  "EGY": "EG",
  "WESTERNSAHARA": "EH",
  "الصحراءالغربية": "EH",
  "TANEZROFTTUTRIMT": "EH",
  "EH": "EH",
  "ESH": "EH",
  "ERITREA": "ER",
  "ኤርትራ": "ER",
  "STATEERITREA": "ER",
  "STATEOFERITREA": "ER",
  "ሃገረኤርትራ": "ER",
  "DAWLATIRITRIYA": "ER",
  "ʾERTRA": "ER",
  "IRITRIYA": "ER",
  "ER": "ER",
  "ERI": "ER",
  "SPAIN": "ES",
  "ESPANA": "ES",
  "KINGDOMSPAIN": "ES",
  "KINGDOMOFSPAIN": "ES",
  "REINODEESPANA": "ES",
  "ES": "ES",
  "ESP": "ES",
  "ETHIOPIA": "ET",
  "ኢትዮጵያ": "ET",
  "ʾITYOPPYA": "ET",
  "FEDERALDEMOCRATICREPUBLICETHIOPIA": "ET",
  "FEDERALDEMOCRATICREPUBLICOFETHIOPIA": "ET",
  "የኢትዮጵያፌዴራላዊዲሞክራሲያዊሪፐብሊክ": "ET",
  "ET": "ET",
  "ETH": "ET",
  "FINLAND": "FI",
  "SUOMI": "FI",
  "REPUBLICFINLAND": "FI",
  "REPUBLICOFFINLAND": "FI",
  "SUOMENTASAVALTA": "FI",
  "REPUBLIKENFINLAND": "FI",
  "FI": "FI",
  "FIN": "FI",
  "FIJI": "FJ",
  "VITI": "FJ",
  "REPUBLICFIJI": "FJ",
  "REPUBLICOFFIJI": "FJ",
  "MATANITUKOVITI": "FJ",
  "FIJIGANARAJYA": "FJ",
  "FJ": "FJ",
  "FJI": "FJ",
  "FALKLANDISLANDSMALVINAS": "FK",
  "FALKLANDISLANDS": "FK",
  "ISLASMALVINAS": "FK",
  "FK": "FK",
  "FLK": "FK",
  "MICRONESIAFEDERATEDSTATES": "FM",
  "MICRONESIAFEDERATEDSTATESOF": "FM",
  "FEDERATEDSTATESMICRONESIA": "FM",
  "FEDERATEDSTATESOFMICRONESIA": "FM",
  "MICRONESIA": "FM",
  "FM": "FM",
  "FSM": "FM",
  "FAROEISLANDS": "FO",
  "FOROYAR": "FO",
  "FAEROERNE": "FO",
  "FO": "FO",
  "FRO": "FO",
  "FRANCE": "FR",
  "FRENCHREPUBLIC": "FR",
  "REPUBLIQUEFRANCAISE": "FR",
  "FR": "FR",
  "FRA": "FR",
  "GABON": "GA",
  "GABONESEREPUBLIC": "GA",
  "REPUBLIQUEGABONAISE": "GA",
  "GA": "GA",
  "GAB": "GA",
  "ENGLAND": "GB",
  "GB": "GB",
  "GBR": "GB",
  "GREATBRITAIN": "GB",
  "NIRELAND": "GB",
  "NORTHERNIRELAND": "GB",
  "SCOTLAND": "GB",
  "UK": "GB",
  "UNITEDKINGDOMGBNI": "GB",
  "UNITEDKINGDOMOFGBANDNI": "GB",
  "UNITEDKINGDOMGBNORTHERNIRELAND": "GB",
  "UNITEDKINGDOMOFGBANDNORTHERNIRELAND": "GB",
  "UNITEDKINGDOMGREATBRITAINNIRELAND": "GB",
  "UNITEDKINGDOMOFGREATBRITAINANDNIRELAND": "GB",
  "UNITEDKINGDOMGREATBRITAINNI": "GB",
  "UNITEDKINGDOMOFGREATBRITAINANDNI": "GB",
  "UNITEDKINGDOMGREATBRITAINNORTHERNIRELAND": "GB",
  "UNITEDKINGDOMOFGREATBRITAINANDNORTHERNIRELAND": "GB",
  "UNITEDKINGDOMGREATBRITAIN": "GB",
  "UNITEDKINGDOMOFGREATBRITAIN": "GB",
  "UNITEDKINGDOM": "GB",
  "WALES": "GB",
  "GRENADA": "GD",
  "GD": "GD",
  "GRD": "GD",
  "GEORGIA": "GE",
  "ᲡᲐᲥᲐᲠᲗᲕᲔᲚᲝ": "GE",
  "SAKARTVELO": "GE",
  "GE": "GE",
  "GEO": "GE",
  "FRENCHGUIANA": "GF",
  "GUYANEFRANCAISE": "GF",
  "GUIANA": "GF",
  "GUYANE": "GF",
  "GF": "GF",
  "GUF": "GF",
  "GUERNSEY": "GG",
  "BAILIWICKGUERNSEY": "GG",
  "BAILIWICKOFGUERNSEY": "GG",
  "BAILLIAGEDEGUERNESEY": "GG",
  "GG": "GG",
  "GGY": "GG",
  "GHANA": "GH",
  "GH": "GH",
  "GHA": "GH",
  "GIBRALTAR": "GI",
  "GI": "GI",
  "GIB": "GI",
  "GREENLAND": "GL",
  "KALAALLITNUNAAT": "GL",
  "GRONLAND": "GL",
  "GL": "GL",
  "GRL": "GL",
  "GAMBIA": "GM",
  "THEGAMBIA": "GM",
  "REPUBLICGAMBIA": "GM",
  "REPUBLICOFTHEGAMBIA": "GM",
  "GM": "GM",
  "GMB": "GM",
  "GUINEA": "GN",
  "GUINEE": "GN",
  "REPUBLICGUINEA": "GN",
  "REPUBLICOFGUINEA": "GN",
  "REPUBLIQUEDEGUINEE": "GN",
  "GN": "GN",
  "GIN": "GN",
  "GUADELOUPE": "GP",
  "GWADLOUP": "GP",
  "GP": "GP",
  "GLP": "GP",
  "EQUATORIALGUINEA": "GQ",
  "GUINEAECUATORIAL": "GQ",
  "REPUBLICEQUATORIALGUINEA": "GQ",
  "REPUBLICOFEQUATORIALGUINEA": "GQ",
  "REPUBLICADEGUINEAECUATORIAL": "GQ",
  "REPUBLIQUEDEGUINEEEQUATORIALE": "GQ",
  "REPUBLICADAGUINEEQUATORIAL": "GQ",
  "GQ": "GQ",
  "GNQ": "GQ",
  "GREECE": "GR",
  "ΕΛΛΆΔΑ": "GR",
  "ELLADA": "GR",
  "HELLENICREPUBLIC": "GR",
  "ΕΛΛΗΝΙΚΉΔΗΜΟΚΡΑΤΊΑ": "GR",
  "GR": "GR",
  "GRC": "GR",
  "SOUTHGEORGIASOUTHSANDWICHISLANDS": "GS",
  "SOUTHGEORGIAANDTHESOUTHSANDWICHISLANDS": "GS",
  "SOUTHGEORGIA": "GS",
  "GS": "GS",
  "SGS": "GS",
  "GUATEMALA": "GT",
  "GT": "GT",
  "GTM": "GT",
  "GUAM": "GU",
  "GUAHAN": "GU",
  "GU": "GU",
  "GUM": "GU",
  "GUINEABISSAU": "GW",
  "GUINEBISSAU": "GW",
  "REPUBLICGUINEABISSAU": "GW",
  "REPUBLICOFGUINEABISSAU": "GW",
  "REPUBLICADAGUINEBISSAU": "GW",
  "GW": "GW",
  "GNB": "GW",
  "GUYANA": "GY",
  "COOPERATIVEREPUBLICGUYANA": "GY",
  "COOPERATIVEREPUBLICOFGUYANA": "GY",
  "GY": "GY",
  "GUY": "GY",
  "HONGKONG": "HK",
  "香港": "HK",
  "HK": "HK",
  "HKG": "HK",
  "HKSAR": "HK",
  "HONGKONGSPECIALADMINISTRATIVEREGIONPEOPLESREPUBLICCHINA": "HK",
  "HONGKONGSPECIALADMINISTRATIVEREGIONOFTHEPEOPLESREPUBLICOFCHINA": "HK",
  "HONGKONGSARCHINA": "HK",
  "HEARDISLANDMCDONALDISLANDS": "HM",
  "HEARDISLANDANDMCDONALDISLANDS": "HM",
  "HM": "HM",
  "HMD": "HM",
  "HONDURAS": "HN",
  "REPUBLICHONDURAS": "HN",
  "REPUBLICOFHONDURAS": "HN",
  "REPUBLICADEHONDURAS": "HN",
  "HN": "HN",
  "HND": "HN",
  "CROATIA": "HR",
  "HRVATSKA": "HR",
  "REPUBLICCROATIA": "HR",
  "REPUBLICOFCROATIA": "HR",
  "REPUBLIKAHRVATSKA": "HR",
  "HR": "HR",
  "HRV": "HR",
  "HAITI": "HT",
  "REPUBLICHAITI": "HT",
  "REPUBLICOFHAITI": "HT",
  "REPUBLIQUEDHAITI": "HT",
  "REPIBLIKAYITI": "HT",
  "HT": "HT",
  "HTI": "HT",
  "HUNGARY": "HU",
  "MAGYARORSZAG": "HU",
  "HU": "HU",
  "HUN": "HU",
  "INDONESIA": "ID",
  "REPUBLICINDONESIA": "ID",
  "REPUBLICOFINDONESIA": "ID",
  "REPUBLIKINDONESIA": "ID",
  "ID": "ID",
  "IDN": "ID",
  "IRELAND": "IE",
  "EIRE": "IE",
  "REPUBLICIRELAND": "IE",
  "REPUBLICOFIRELAND": "IE",
  "POBLACHTNAHEIREANN": "IE",
  "IE": "IE",
  "IRL": "IE",
  "ISRAEL": "IL",
  "יִשְׂרָאֵל": "IL",
  "STATEISRAEL": "IL",
  "STATEOFISRAEL": "IL",
  "MEDINATYISRAEL": "IL",
  "IL": "IL",
  "ISR": "IL",
  "ISLEMAN": "IM",
  "ISLEOFMAN": "IM",
  "ELLANVANNIN": "IM",
  "MANN": "IM",
  "MANNIN": "IM",
  "IM": "IM",
  "IMN": "IM",
  "INDIA": "IN",
  "भारत": "IN",
  "BHARAT": "IN",
  "REPUBLICINDIA": "IN",
  "REPUBLICOFINDIA": "IN",
  "BHARATGANRAJYA": "IN",
  "IN": "IN",
  "IND": "IN",
  "BRITISHINDIANOCEANTERRITORY": "IO",
  "IO": "IO",
  "IOT": "IO",
  "IRAQ": "IQ",
  "العراق": "IQ",
  "REPUBLICIRAQ": "IQ",
  "REPUBLICOFIRAQ": "IQ",
  "JUMHURIYYATAL‘IRAQ": "IQ",
  "IQ": "IQ",
  "IRQ": "IQ",
  "IRANISLAMICREPUBLIC": "IR",
  "IRANISLAMICREPUBLICOF": "IR",
  "IRAN": "IR",
  "ISLAMICREPUBLICIRAN": "IR",
  "ISLAMICREPUBLICOFIRAN": "IR",
  "JOMHURIYEESLAMIYEIRAN": "IR",
  "IR": "IR",
  "IRN": "IR",
  "ICELAND": "IS",
  "ISLAND": "IS",
  "REPUBLICICELAND": "IS",
  "REPUBLICOFICELAND": "IS",
  "LYDVELDIDISLAND": "IS",
  "IS": "IS",
  "ISL": "IS",
  "ITALY": "IT",
  "ITALIA": "IT",
  "ITALIANREPUBLIC": "IT",
  "REPUBBLICAITALIANA": "IT",
  "IT": "IT",
  "ITA": "IT",
  "JERSEY": "JE",
  "BAILIWICKJERSEY": "JE",
  "BAILIWICKOFJERSEY": "JE",
  "BAILLIAGEDEJERSEY": "JE",
  "BAILLIAGEDEJERRI": "JE",
  "JE": "JE",
  "JEY": "JE",
  "JAMAICA": "JM",
  "JM": "JM",
  "JAM": "JM",
  "JORDAN": "JO",
  "الأردن": "JO",
  "HASHEMITEKINGDOMJORDAN": "JO",
  "HASHEMITEKINGDOMOFJORDAN": "JO",
  "ALMAMLAKAHALURDUNIYAHALHASHIMIYAH": "JO",
  "JO": "JO",
  "JOR": "JO",
  "JAPAN": "JP",
  "日本": "JP",
  "NIPPON": "JP",
  "NIHON": "JP",
  "JP": "JP",
  "JPN": "JP",
  "KENYA": "KE",
  "REPUBLICKENYA": "KE",
  "REPUBLICOFKENYA": "KE",
  "JAMHURIYAKENYA": "KE",
  "KE": "KE",
  "KEN": "KE",
  "KYRGYZSTAN": "KG",
  "KIRGIZSTAN": "KG",
  "KIRGIZIYA": "KG",
  "KYRGYZREPUBLIC": "KG",
  "KIRGIZRESPUBLIKASI": "KG",
  "KYRGYZRESPUBLIKASY": "KG",
  "KG": "KG",
  "KGZ": "KG",
  "CAMBODIA": "KH",
  "KAMPUCHEA": "KH",
  "KINGDOMCAMBODIA": "KH",
  "KINGDOMOFCAMBODIA": "KH",
  "KH": "KH",
  "KHM": "KH",
  "KIRIBATI": "KI",
  "REPUBLICKIRIBATI": "KI",
  "REPUBLICOFKIRIBATI": "KI",
  "RIBABERIKIKIRIBATI": "KI",
  "KI": "KI",
  "KIR": "KI",
  "COMOROS": "KM",
  "KOMORI": "KM",
  "UNIONCOMOROS": "KM",
  "UNIONOFTHECOMOROS": "KM",
  "UNIONDESCOMORES": "KM",
  "UDZIMAWAKOMORI": "KM",
  "ALITTIHADALQUMURI": "KM",
  "KM": "KM",
  "COM": "KM",
  "SAINTKITTSNEVIS": "KN",
  "SAINTKITTSANDNEVIS": "KN",
  "FEDERATIONSAINTCHRISTOPHERNEVIS": "KN",
  "FEDERATIONOFSAINTCHRISTOPHERANDNEVIS": "KN",
  "KN": "KN",
  "KNA": "KN",
  "KOREADEMOCRATICPEOPLESREPUBLIC": "KP",
  "KOREADEMOCRATICPEOPLESREPUBLICOF": "KP",
  "NORTHKOREA": "KP",
  "북한": "KP",
  "DEMOCRATICPEOPLESREPUBLICKOREA": "KP",
  "DEMOCRATICPEOPLESREPUBLICOFKOREA": "KP",
  "조선민주주의인민공화국": "KP",
  "CHOSONMINJUJUUIINMINKONGHWAGUK": "KP",
  "KP": "KP",
  "PRK": "KP",
  "KOREAREPUBLIC": "KR",
  "KOREAREPUBLICOF": "KR",
  "SOUTHKOREA": "KR",
  "대한민국": "KR",
  "REPUBLICKOREA": "KR",
  "REPUBLICOFKOREA": "KR",
  "KR": "KR",
  "KOR": "KR",
  "KUWAIT": "KW",
  "الكويت": "KW",
  "STATEKUWAIT": "KW",
  "STATEOFKUWAIT": "KW",
  "DAWLATALKUWAIT": "KW",
  "KW": "KW",
  "KWT": "KW",
  "CAYMANISLANDS": "KY",
  "KY": "KY",
  "CYM": "KY",
  "KAZAKHSTAN": "KZ",
  "ҚAZAҚSTAN": "KZ",
  "QAZAQSTAN": "KZ",
  "KAZAHSTAN": "KZ",
  "REPUBLICKAZAKHSTAN": "KZ",
  "REPUBLICOFKAZAKHSTAN": "KZ",
  "ҚAZAҚSTANRESPUBLIKASI": "KZ",
  "QAZAQSTANRESPUBLIKASI": "KZ",
  "RESPUBLIKAKAZAHSTAN": "KZ",
  "RESPUBLIKAKAZAKHSTAN": "KZ",
  "KZ": "KZ",
  "KAZ": "KZ",
  "LAOPEOPLESDEMOCRATICREPUBLIC": "LA",
  "LAOS": "LA",
  "ສປປລາວ": "LA",
  "SATHALANALATPAXATHIPATAIPAXAXONLAO": "LA",
  "LA": "LA",
  "LAO": "LA",
  "LEBANON": "LB",
  "لبنان": "LB",
  "LEBANESEREPUBLIC": "LB",
  "ALJUMHURIYAHALLIBNANIYAH": "LB",
  "LB": "LB",
  "LBN": "LB",
  "SAINTLUCIA": "LC",
  "LC": "LC",
  "LCA": "LC",
  "LIECHTENSTEIN": "LI",
  "PRINCIPALITYLIECHTENSTEIN": "LI",
  "PRINCIPALITYOFLIECHTENSTEIN": "LI",
  "FURSTENTUMLIECHTENSTEIN": "LI",
  "LI": "LI",
  "LIE": "LI",
  "SRILANKA": "LK",
  "SRILAMKAVA": "LK",
  "ILANKAI": "LK",
  "DEMOCRATICSOCIALISTREPUBLICSRILANKA": "LK",
  "DEMOCRATICSOCIALISTREPUBLICOFSRILANKA": "LK",
  "LK": "LK",
  "LKA": "LK",
  "LIBERIA": "LR",
  "REPUBLICLIBERIA": "LR",
  "REPUBLICOFLIBERIA": "LR",
  "LR": "LR",
  "LBR": "LR",
  "LESOTHO": "LS",
  "KINGDOMLESOTHO": "LS",
  "KINGDOMOFLESOTHO": "LS",
  "MUSOOALESOTHO": "LS",
  "LS": "LS",
  "LSO": "LS",
  "LITHUANIA": "LT",
  "LIETUVA": "LT",
  "REPUBLICLITHUANIA": "LT",
  "REPUBLICOFLITHUANIA": "LT",
  "LIETUVOSRESPUBLIKA": "LT",
  "LT": "LT",
  "LTU": "LT",
  "LUXEMBOURG": "LU",
  "GRANDDUCHYLUXEMBOURG": "LU",
  "GRANDDUCHYOFLUXEMBOURG": "LU",
  "GRANDDUCHEDELUXEMBOURG": "LU",
  "GROSSHERZOGTUMLUXEMBURG": "LU",
  "GROUSSHERZOGTUMLETZEBUERG": "LU",
  "LU": "LU",
  "LUX": "LU",
  "LATVIA": "LV",
  "LATVIJA": "LV",
  "REPUBLICLATVIA": "LV",
  "REPUBLICOFLATVIA": "LV",
  "LATVIJASREPUBLIKA": "LV",
  "LV": "LV",
  "LVA": "LV",
  "LIBYA": "LY",
  "‏ليبيا": "LY",
  "STATELIBYA": "LY",
  "STATEOFLIBYA": "LY",
  "DAWLATLIBYA": "LY",
  "LY": "LY",
  "LBY": "LY",
  "MOROCCO": "MA",
  "المغرب": "MA",
  "KINGDOMMOROCCO": "MA",
  "KINGDOMOFMOROCCO": "MA",
  "ALMAMLAKAHALMAGRIBIYAH": "MA",
  "MA": "MA",
  "MAR": "MA",
  "MONACO": "MC",
  "PRINCIPALITYMONACO": "MC",
  "PRINCIPALITYOFMONACO": "MC",
  "PRINCIPAUTEDEMONACO": "MC",
  "MC": "MC",
  "MCO": "MC",
  "MOLDOVAREPUBLIC": "MD",
  "MOLDOVAREPUBLICOF": "MD",
  "MOLDOVA": "MD",
  "REPUBLICMOLDOVA": "MD",
  "REPUBLICOFMOLDOVA": "MD",
  "REPUBLICAMOLDOVA": "MD",
  "MD": "MD",
  "MDA": "MD",
  "ME": "ME",
  "MNE": "ME",
  "MONTENEGRO": "ME",
  "MF": "MF",
  "MAF": "MF",
  "SAINTMARTINFRENCHPART": "MF",
  "SAINTMARTIN": "MF",
  "MADAGASCAR": "MG",
  "MADAGASIKARA": "MG",
  "REPUBLICMADAGASCAR": "MG",
  "REPUBLICOFMADAGASCAR": "MG",
  "REPOBLIKANIMADAGASIKARA": "MG",
  "REPUBLIQUEDEMADAGASCAR": "MG",
  "MG": "MG",
  "MDG": "MG",
  "MARSHALLISLANDS": "MH",
  "M̧AJEL": "MH",
  "REPUBLICMARSHALLISLANDS": "MH",
  "REPUBLICOFTHEMARSHALLISLANDS": "MH",
  "AOLEPANAOROKINM̧AJEL": "MH",
  "MH": "MH",
  "MHL": "MH",
  "NORTHMACEDONIA": "MK",
  "REPUBLICMACEDONIA": "MK",
  "REPUBLICOFMACEDONIA": "MK",
  "MAKEDONIЈA": "MK",
  "REPUBLIKAMAKEDONIЈA": "MK",
  "MK": "MK",
  "MKD": "MK",
  "MACEDONIAFORMERYUGOSLAVREPUBLIC": "MK",
  "MACEDONIATHEFORMERYUGOSLAVREPUBLICOF": "MK",
  "NORTHMACEDONIAREPUBLIC": "MK",
  "NORTHMACEDONIAREPUBLICOF": "MK",
  "MALI": "ML",
  "REPUBLICMALI": "ML",
  "REPUBLICOFMALI": "ML",
  "REPUBLIQUEDUMALI": "ML",
  "ML": "ML",
  "MLI": "ML",
  "MM": "MM",
  "MMR": "MM",
  "MYANMAR": "MM",
  "MONGOLIA": "MN",
  "MONGOLULS": "MN",
  "MN": "MN",
  "MNG": "MN",
  "MACAO": "MO",
  "MACAU": "MO",
  "澳門": "MO",
  "澳门": "MO",
  "MACAOSPECIALADMINISTRATIVEREGIONPEOPLESREPUBLICCHINA": "MO",
  "MACAOSPECIALADMINISTRATIVEREGIONOFTHEPEOPLESREPUBLICOFCHINA": "MO",
  "中華人民共和國澳門特別行政區": "MO",
  "REGIAOADMINISTRATIVAESPECIALDEMACAUDAREPUBLICAPOPULARDACHINA": "MO",
  "MO": "MO",
  "MAC": "MO",
  "NORTHERNMARIANAISLANDS": "MP",
  "COMMONWEALTHNORTHERNMARIANAISLANDS": "MP",
  "COMMONWEALTHOFTHENORTHERNMARIANAISLANDS": "MP",
  "SANKATTANSIHANAISLASMARIANAS": "MP",
  "MP": "MP",
  "MNP": "MP",
  "MARTINIQUE": "MQ",
  "MQ": "MQ",
  "MTQ": "MQ",
  "MAURITANIA": "MR",
  "موريتانيا": "MR",
  "ISLAMICREPUBLICMAURITANIA": "MR",
  "ISLAMICREPUBLICOFMAURITANIA": "MR",
  "ALJUMHURIYYAHALʾISLAMIYYAHALMURITANIYYAH": "MR",
  "MR": "MR",
  "MRT": "MR",
  "MONTSERRAT": "MS",
  "MS": "MS",
  "MSR": "MS",
  "MALTA": "MT",
  "REPUBLICMALTA": "MT",
  "REPUBLICOFMALTA": "MT",
  "REPUBBLIKATAMALTA": "MT",
  "MT": "MT",
  "MLT": "MT",
  "MAURITIUS": "MU",
  "MAURICE": "MU",
  "REPUBLICMAURITIUS": "MU",
  "REPUBLICOFMAURITIUS": "MU",
  "REPUBLIQUEDEMAURICE": "MU",
  "MU": "MU",
  "MUS": "MU",
  "MALDIVES": "MV",
  "MALDIVEISLANDS": "MV",
  "REPUBLICMALDIVES": "MV",
  "REPUBLICOFTHEMALDIVES": "MV",
  "DHIVEHIRAAJJEYGEJUMHOORIYYA": "MV",
  "MV": "MV",
  "MDV": "MV",
  "MALAWI": "MW",
  "REPUBLICMALAWI": "MW",
  "REPUBLICOFMALAWI": "MW",
  "MW": "MW",
  "MWI": "MW",
  "MEXICO": "MX",
  "MEXICANOS": "MX",
  "UNITEDMEXICANSTATES": "MX",
  "ESTADOSUNIDOSMEXICANOS": "MX",
  "MX": "MX",
  "MEX": "MX",
  "MALAYSIA": "MY",
  "MY": "MY",
  "MYS": "MY",
  "MOZAMBIQUE": "MZ",
  "MOCAMBIQUE": "MZ",
  "REPUBLICMOZAMBIQUE": "MZ",
  "REPUBLICOFMOZAMBIQUE": "MZ",
  "REPUBLICADEMOCAMBIQUE": "MZ",
  "MZ": "MZ",
  "MOZ": "MZ",
  "NAMIBIA": "NA",
  "NAMIBIE": "NA",
  "REPUBLICNAMIBIA": "NA",
  "REPUBLICOFNAMIBIA": "NA",
  "NA": "NA",
  "NAM": "NA",
  "NEWCALEDONIA": "NC",
  "NOUVELLECALEDONIE": "NC",
  "NC": "NC",
  "NCL": "NC",
  "NIGER": "NE",
  "NIJAR": "NE",
  "REPUBLICNIGER": "NE",
  "REPUBLICOFNIGER": "NE",
  "REPUBLIQUEDUNIGER": "NE",
  "NE": "NE",
  "NER": "NE",
  "NORFOLKISLAND": "NF",
  "TERRITORYNORFOLKISLAND": "NF",
  "TERRITORYOFNORFOLKISLAND": "NF",
  "TERATRINORFKAILEN": "NF",
  "TERATRIOFNORFKAILEN": "NF",
  "NF": "NF",
  "NFK": "NF",
  "NIGERIA": "NG",
  "NIJERIYA": "NG",
  "NAIJIRIA": "NG",
  "FEDERALREPUBLICNIGERIA": "NG",
  "FEDERALREPUBLICOFNIGERIA": "NG",
  "NG": "NG",
  "NGA": "NG",
  "NICARAGUA": "NI",
  "REPUBLICNICARAGUA": "NI",
  "REPUBLICOFNICARAGUA": "NI",
  "REPUBLICADENICARAGUA": "NI",
  "NI": "NI",
  "NIC": "NI",
  "NETHERLANDS": "NL",
  "NEDERLAND": "NL",
  "HOLLAND": "NL",
  "NL": "NL",
  "NLD": "NL",
  "NORWAY": "NO",
  "NORGE": "NO",
  "NOREG": "NO",
  "KINGDOMNORWAY": "NO",
  "KINGDOMOFNORWAY": "NO",
  "KONGERIKETNORGE": "NO",
  "KONGERIKETNOREG": "NO",
  "NO": "NO",
  "NOR": "NO",
  "NEPAL": "NP",
  "नेपाल": "NP",
  "FEDERALDEMOCRATICREPUBLICNEPAL": "NP",
  "FEDERALDEMOCRATICREPUBLICOFNEPAL": "NP",
  "LOKTANTRIKGANATANTRANEPAL": "NP",
  "NP": "NP",
  "NPL": "NP",
  "NAURU": "NR",
  "NAOERO": "NR",
  "PLEASANTISLAND": "NR",
  "REPUBLICNAURU": "NR",
  "REPUBLICOFNAURU": "NR",
  "RIPUBLIKNAOERO": "NR",
  "NR": "NR",
  "NRU": "NR",
  "NIUE": "NU",
  "NU": "NU",
  "NIU": "NU",
  "NEWZEALAND": "NZ",
  "AOTEAROA": "NZ",
  "NZ": "NZ",
  "NZL": "NZ",
  "OMAN": "OM",
  "عمان": "OM",
  "SULTANATEOMAN": "OM",
  "SULTANATEOFOMAN": "OM",
  "SALTANATʻUMAN": "OM",
  "OM": "OM",
  "OMN": "OM",
  "PANAMA": "PA",
  "REPUBLICPANAMA": "PA",
  "REPUBLICOFPANAMA": "PA",
  "REPUBLICADEPANAMA": "PA",
  "PA": "PA",
  "PAN": "PA",
  "PERU": "PE",
  "REPUBLICPERU": "PE",
  "REPUBLICOFPERU": "PE",
  "REPUBLICADELPERU": "PE",
  "PE": "PE",
  "PER": "PE",
  "FRENCHPOLYNESIA": "PF",
  "POLYNESIEFRANCAISE": "PF",
  "PORINETIAFARANI": "PF",
  "PF": "PF",
  "PYF": "PF",
  "PAPUANEWGUINEA": "PG",
  "PAPUANIUGINI": "PG",
  "INDEPENDENTSTATEPAPUANEWGUINEA": "PG",
  "INDEPENDENTSTATEOFPAPUANEWGUINEA": "PG",
  "INDEPENDENSTETBILONGPAPUANIUGINI": "PG",
  "PG": "PG",
  "PNG": "PG",
  "PHILIPPINES": "PH",
  "PILIPINAS": "PH",
  "REPUBLICPHILIPPINES": "PH",
  "REPUBLICOFTHEPHILIPPINES": "PH",
  "REPUBLIKANGPILIPINAS": "PH",
  "PH": "PH",
  "PHL": "PH",
  "PAKISTAN": "PK",
  "ISLAMICREPUBLICPAKISTAN": "PK",
  "ISLAMICREPUBLICOFPAKISTAN": "PK",
  "ISLAMIJUMHURIYAEHPAKISTAN": "PK",
  "PK": "PK",
  "PAK": "PK",
  "POLAND": "PL",
  "POLSKA": "PL",
  "REPUBLICPOLAND": "PL",
  "REPUBLICOFPOLAND": "PL",
  "RZECZPOSPOLITAPOLSKA": "PL",
  "PL": "PL",
  "POL": "PL",
  "SAINTPIERREMIQUELON": "PM",
  "SAINTPIERREANDMIQUELON": "PM",
  "SAINTPIERREETMIQUELON": "PM",
  "COLLECTIVITETERRITORIALEDESAINTPIERREETMIQUELON": "PM",
  "PM": "PM",
  "SPM": "PM",
  "PITCAIRN": "PN",
  "PITCAIRNISLANDS": "PN",
  "PITCAIRNHENDERSONDUCIEOENOISLANDS": "PN",
  "PITCAIRNHENDERSONDUCIEANDOENOISLANDS": "PN",
  "PN": "PN",
  "PCN": "PN",
  "PUERTORICO": "PR",
  "COMMONWEALTHPUERTORICO": "PR",
  "COMMONWEALTHOFPUERTORICO": "PR",
  "ESTADOLIBREASOCIADODEPUERTORICO": "PR",
  "PR": "PR",
  "PRI": "PR",
  "PS": "PS",
  "PSE": "PS",
  "PALESTINESTATE": "PS",
  "PALESTINESTATEOF": "PS",
  "PALESTINIANTERRITORYOCCUPIED": "PS",
  "STATEPALESTINE": "PS",
  "STATEOFPALESTINE": "PS",
  "PALESTINE": "PS",
  "PORTUGAL": "PT",
  "PORTUGUESA": "PT",
  "PORTUGUESEREPUBLIC": "PT",
  "REPUBLICAPORTUGUESA": "PT",
  "PT": "PT",
  "PRT": "PT",
  "PALAU": "PW",
  "REPUBLICPALAU": "PW",
  "REPUBLICOFPALAU": "PW",
  "BELUUERABELAU": "PW",
  "PW": "PW",
  "PLW": "PW",
  "PARAGUAY": "PY",
  "REPUBLICPARAGUAY": "PY",
  "REPUBLICOFPARAGUAY": "PY",
  "REPUBLICADELPARAGUAY": "PY",
  "TETAPARAGUAI": "PY",
  "PY": "PY",
  "PRY": "PY",
  "QATAR": "QA",
  "قطر": "QA",
  "STATEQATAR": "QA",
  "STATEOFQATAR": "QA",
  "DAWLATQATAR": "QA",
  "QA": "QA",
  "QAT": "QA",
  "REUNION": "RE",
  "LAREUNION": "RE",
  "RE": "RE",
  "REU": "RE",
  "ROMANIA": "RO",
  "RUMANIA": "RO",
  "ROUMANIA": "RO",
  "RO": "RO",
  "ROU": "RO",
  "RS": "RS",
  "SRB": "RS",
  "SERBIA": "RS",
  "RUSSIANFEDERATION": "RU",
  "RUSSIA": "RU",
  "ROSSIYA": "RU",
  "ROSSIISKAYAFEDERATSIYA": "RU",
  "ROSSIYSKAYAFEDERATSIYA": "RU",
  "RU": "RU",
  "RUS": "RU",
  "RWANDA": "RW",
  "REPUBLICRWANDA": "RW",
  "REPUBLICOFRWANDA": "RW",
  "REPUBULIKAYURWANDA": "RW",
  "REPUBLIQUEDURWANDA": "RW",
  "RW": "RW",
  "RWA": "RW",
  "SAUDIARABIA": "SA",
  "العربيةالسعودية": "SA",
  "KINGDOMSAUDIARABIA": "SA",
  "KINGDOMOFSAUDIARABIA": "SA",
  "ALMAMLAKAHAL‘ARABIYYAHASSU‘UDIYYAH": "SA",
  "SA": "SA",
  "SAU": "SA",
  "KSA": "SA",
  "SOLOMONISLANDS": "SB",
  "SB": "SB",
  "SLB": "SB",
  "SEYCHELLES": "SC",
  "REPUBLICSEYCHELLES": "SC",
  "REPUBLICOFSEYCHELLES": "SC",
  "REPIBLIKSESEL": "SC",
  "REPUBLIQUEDESSEYCHELLES": "SC",
  "SC": "SC",
  "SYC": "SC",
  "SUDAN": "SD",
  "السودان": "SD",
  "REPUBLICSUDAN": "SD",
  "REPUBLICOFTHESUDAN": "SD",
  "JUMHURIYATASSUDAN": "SD",
  "SD": "SD",
  "SDN": "SD",
  "SWEDEN": "SE",
  "SVERIGE": "SE",
  "KINGDOMSWEDEN": "SE",
  "KINGDOMOFSWEDEN": "SE",
  "KONUNGARIKETSVERIGE": "SE",
  "SE": "SE",
  "SWE": "SE",
  "SINGAPORE": "SG",
  "SINGAPURA": "SG",
  "REPUBLIKSINGAPURA": "SG",
  "新加坡共和国": "SG",
  "SG": "SG",
  "SGP": "SG",
  "SAINTHELENAASCENSIONTRISTANDACUNHA": "SH",
  "SAINTHELENAASCENSIONANDTRISTANDACUNHA": "SH",
  "SAINTHELENA": "SH",
  "SH": "SH",
  "SHN": "SH",
  "SLOVENIA": "SI",
  "SLOVENIJA": "SI",
  "REPUBLICSLOVENIA": "SI",
  "REPUBLICOFSLOVENIA": "SI",
  "REPUBLIKASLOVENIJA": "SI",
  "SI": "SI",
  "SVN": "SI",
  "SVALBARDJANMAYEN": "SJ",
  "SVALBARDANDJANMAYEN": "SJ",
  "SVALBARDOGJANMAYEN": "SJ",
  "SVALBARDJANMAYENISLANDS": "SJ",
  "SVALBARDANDJANMAYENISLANDS": "SJ",
  "SJ": "SJ",
  "SJM": "SJ",
  "SLOVAKIA": "SK",
  "SLOVENSKO": "SK",
  "SLOVAKREPUBLIC": "SK",
  "SLOVENSKAREPUBLIKA": "SK",
  "SK": "SK",
  "SVK": "SK",
  "SIERRALEONE": "SL",
  "REPUBLICSIERRALEONE": "SL",
  "REPUBLICOFSIERRALEONE": "SL",
  "SL": "SL",
  "SLE": "SL",
  "SANMARINO": "SM",
  "REPUBLICSANMARINO": "SM",
  "REPUBLICOFSANMARINO": "SM",
  "REPUBBLICADISANMARINO": "SM",
  "SM": "SM",
  "SMR": "SM",
  "SENEGAL": "SN",
  "REPUBLICSENEGAL": "SN",
  "REPUBLICOFSENEGAL": "SN",
  "REPUBLIQUEDUSENEGAL": "SN",
  "SN": "SN",
  "SEN": "SN",
  "SOMALIA": "SO",
  "SOOMAALIYA": "SO",
  "ASSUMAL": "SO",
  "FEDERALREPUBLICSOMALIA": "SO",
  "FEDERALREPUBLICOFSOMALIA": "SO",
  "JAMHUURIYADDAFEDERAALKASOOMAALIYA": "SO",
  "JUMHURIYYATASSUMALALFIDERALIYYA": "SO",
  "SO": "SO",
  "SOM": "SO",
  "SURINAME": "SR",
  "SARNAM": "SR",
  "SRANANGRON": "SR",
  "REPUBLICSURINAME": "SR",
  "REPUBLICOFSURINAME": "SR",
  "REPUBLIEKSURINAME": "SR",
  "SR": "SR",
  "SUR": "SR",
  "SOUTHSUDAN": "SS",
  "SS": "SS",
  "SSD": "SS",
  "SAOTOMEPRINCIPE": "ST",
  "SAOTOMEANDPRINCIPE": "ST",
  "SAOTOMEEPRINCIPE": "ST",
  "DEMOCRATICREPUBLICSAOTOMEPRINCIPE": "ST",
  "DEMOCRATICREPUBLICOFSAOTOMEANDPRINCIPE": "ST",
  "REPUBLICADEMOCRATICADESAOTOMEEPRINCIPE": "ST",
  "ST": "ST",
  "STP": "ST",
  "ELSALVADOR": "SV",
  "REPUBLICELSALVADOR": "SV",
  "REPUBLICOFELSALVADOR": "SV",
  "REPUBLICADEELSALVADOR": "SV",
  "SV": "SV",
  "SLV": "SV",
  "SX": "SX",
  "SXM": "SX",
  "SINTMAARTENDUTCHPART": "SX",
  "SINTMAARTEN": "SX",
  "SYRIANARABREPUBLIC": "SY",
  "SYRIA": "SY",
  "سوريا": "SY",
  "ALJUMHURIYAHALʻARABIYAHASSURIYAH": "SY",
  "SY": "SY",
  "SYR": "SY",
  "ESWATINI": "SZ",
  "SWAZILAND": "SZ",
  "WESWATINI": "SZ",
  "SWATINI": "SZ",
  "NGWANE": "SZ",
  "KINGDOMSWAZILAND": "SZ",
  "KINGDOMOFSWAZILAND": "SZ",
  "UMBUSOWASESWATINI": "SZ",
  "SZ": "SZ",
  "SWZ": "SZ",
  "TC": "TC",
  "TCA": "TC",
  "TURKSCAICOSISLANDS": "TC",
  "TURKSANDCAICOSISLANDS": "TC",
  "CHAD": "TD",
  "TCHAD": "TD",
  "REPUBLICCHAD": "TD",
  "REPUBLICOFCHAD": "TD",
  "REPUBLIQUEDUTCHAD": "TD",
  "TD": "TD",
  "TCD": "TD",
  "FRENCHSOUTHERNTERRITORIES": "TF",
  "FRENCHSOUTHERNANTARCTICLANDS": "TF",
  "FRENCHSOUTHERNANDANTARCTICLANDS": "TF",
  "TERRITOIREDESTERRESAUSTRALESETANTARCTIQUESFRANCAISES": "TF",
  "TF": "TF",
  "ATF": "TF",
  "TOGO": "TG",
  "TOGOLESE": "TG",
  "TOGOLESEREPUBLIC": "TG",
  "REPUBLIQUETOGOLAISE": "TG",
  "TG": "TG",
  "TGO": "TG",
  "THAILAND": "TH",
  "ประเทศไทย": "TH",
  "PRATHET": "TH",
  "THAI": "TH",
  "KINGDOMTHAILAND": "TH",
  "KINGDOMOFTHAILAND": "TH",
  "ราชอาณาจักรไทย": "TH",
  "RATCHAANACHAKTHAI": "TH",
  "TH": "TH",
  "THA": "TH",
  "TAJIKISTAN": "TJ",
  "TOҶIKISTON": "TJ",
  "TOCIKISTON": "TJ",
  "REPUBLICTAJIKISTAN": "TJ",
  "REPUBLICOFTAJIKISTAN": "TJ",
  "ҶUMҲURIITOҶIKISTON": "TJ",
  "CUMHURIYITOCIKISTON": "TJ",
  "TJ": "TJ",
  "TJK": "TJ",
  "TOKELAU": "TK",
  "TK": "TK",
  "TKL": "TK",
  "TIMORLESTE": "TL",
  "EASTTIMOR": "TL",
  "DEMOCRATICREPUBLICTIMORLESTE": "TL",
  "DEMOCRATICREPUBLICOFTIMORLESTE": "TL",
  "REPUBLICADEMOCRATICADETIMORLESTE": "TL",
  "REPUBLIKADEMOKRATIKATIMORLESTE": "TL",
  "TL": "TL",
  "TLS": "TL",
  "TURKMENISTAN": "TM",
  "TM": "TM",
  "TKM": "TM",
  "TUNISIA": "TN",
  "تونس": "TN",
  "REPUBLICTUNISIA": "TN",
  "REPUBLICOFTUNISIA": "TN",
  "ALJUMHURIYYAHATTUNISIYYAH": "TN",
  "TN": "TN",
  "TUN": "TN",
  "TONGA": "TO",
  "TO": "TO",
  "TON": "TO",
  "REPUBLICTURKEY": "TR",
  "REPUBLICOFTURKEY": "TR",
  "REPUBLICTURKIYE": "TR",
  "REPUBLICOFTURKIYE": "TR",
  "TR": "TR",
  "TUR": "TR",
  "TURKEY": "TR",
  "TURKIYECUMHURIYETI": "TR",
  "TURKIYE": "TR",
  "TRINIDADTOBAGO": "TT",
  "TRINIDADANDTOBAGO": "TT",
  "REPUBLICTRINIDADTOBAGO": "TT",
  "REPUBLICOFTRINIDADANDTOBAGO": "TT",
  "TT": "TT",
  "TTO": "TT",
  "TUVALU": "TV",
  "TV": "TV",
  "TUV": "TV",
  "TAIWANPROVINCECHINA": "TW",
  "TAIWANPROVINCEOFCHINA": "TW",
  "TAIWAN": "TW",
  "臺灣": "TW",
  "REPUBLICCHINA": "TW",
  "REPUBLICOFCHINA": "TW",
  "中華民國": "TW",
  "ZHONGHUAMINGUO": "TW",
  "TW": "TW",
  "TWN": "TW",
  "TANZANIAUNITEDREPUBLIC": "TZ",
  "TANZANIAUNITEDREPUBLICOF": "TZ",
  "TANZANIA": "TZ",
  "UNITEDREPUBLICTANZANIA": "TZ",
  "UNITEDREPUBLICOFTANZANIA": "TZ",
  "JAMHURIYAMUUNGANOWATANZANIA": "TZ",
  "TZ": "TZ",
  "TZA": "TZ",
  "UKRAINE": "UA",
  "UKRAYINA": "UA",
  "UA": "UA",
  "UKR": "UA",
  "UGANDA": "UG",
  "REPUBLICUGANDA": "UG",
  "REPUBLICOFUGANDA": "UG",
  "JAMHURIYAUGANDA": "UG",
  "UG": "UG",
  "UGA": "UG",
  "UM": "UM",
  "UMI": "UM",
  "UNITEDSTATESMINOROUTLYINGISLANDS": "UM",
  "AMERICA": "US",
  "UNITEDSTATESAMERICA": "US",
  "UNITEDSTATESOFAMERICA": "US",
  "UNITEDSTATES": "US",
  "USOFA": "US",
  "USANDA": "US",
  "US": "US",
  "USA": "US",
  "URUGUAY": "UY",
  "ORIENTALREPUBLICURUGUAY": "UY",
  "ORIENTALREPUBLICOFURUGUAY": "UY",
  "REPUBLICAORIENTALDELURUGUAY": "UY",
  "UY": "UY",
  "URY": "UY",
  "UZBEKISTAN": "UZ",
  "O‘ZBEKISTON": "UZ",
  "REPUBLICUZBEKISTAN": "UZ",
  "REPUBLICOFUZBEKISTAN": "UZ",
  "O‘ZBEKISTONRESPUBLIKASI": "UZ",
  "ЎZBEKISTONRESPUBLIKASI": "UZ",
  "UZ": "UZ",
  "UZB": "UZ",
  "VA": "VA",
  "VAT": "VA",
  "HOLYSEE": "VA",
  "HOLYSEEVATICANCITYSTATE": "VA",
  "VATICAN": "VA",
  "VATICANCITY": "VA",
  "VATICANCITYSTATE": "VA",
  "SAINTVINCENTGRENADINES": "VC",
  "SAINTVINCENTANDTHEGRENADINES": "VC",
  "VC": "VC",
  "VCT": "VC",
  "VENEZUELABOLIVARIANREPUBLIC": "VE",
  "VENEZUELABOLIVARIANREPUBLICOF": "VE",
  "VENEZUELA": "VE",
  "BOLIVARIANREPUBLICVENEZUELA": "VE",
  "BOLIVARIANREPUBLICOFVENEZUELA": "VE",
  "REPUBLICABOLIVARIANADEVENEZUELA": "VE",
  "VE": "VE",
  "VEN": "VE",
  "VG": "VG",
  "VGB": "VG",
  "VIRGINISLANDSBRITISH": "VG",
  "BRITISHVIRGINISLANDS": "VG",
  "VI": "VI",
  "VIR": "VI",
  "VIRGINISLANDSUS": "VI",
  "USVIRGINISLANDS": "VI",
  "AMERICANVIRGINISLANDS": "VI",
  "VIETNAM": "VN",
  "SOCIALISTREPUBLICVIETNAM": "VN",
  "SOCIALISTREPUBLICOFVIETNAM": "VN",
  "CONGHOAXAHOICHUNGHIAVIETNAM": "VN",
  "VN": "VN",
  "VNM": "VN",
  "VANUATU": "VU",
  "REPUBLICVANUATU": "VU",
  "REPUBLICOFVANUATU": "VU",
  "RIPABLIKBLONGVANUATU": "VU",
  "REPUBLIQUEDEVANUATU": "VU",
  "VU": "VU",
  "VUT": "VU",
  "WALLISFUTUNA": "WF",
  "WALLISANDFUTUNA": "WF",
  "WALLISETFUTUNA": "WF",
  "TERRITORYWALLISFUTUNAISLANDS": "WF",
  "TERRITORYOFTHEWALLISANDFUTUNAISLANDS": "WF",
  "TERRITOIREDESILESWALLISETFUTUNA": "WF",
  "WF": "WF",
  "WLF": "WF",
  "SAMOA": "WS",
  "INDEPENDENTSTATESAMOA": "WS",
  "INDEPENDENTSTATEOFSAMOA": "WS",
  "MALOSAʻOLOTOTUTOʻATASIOSAMOA": "WS",
  "WS": "WS",
  "WSM": "WS",
  "YEMEN": "YE",
  "اليَمَن": "YE",
  "YEMENIREPUBLIC": "YE",
  "ALJUMHURIYYAHALYAMANIYYAH": "YE",
  "YE": "YE",
  "YEM": "YE",
  "MAYOTTE": "YT",
  "DEPARTMENTMAYOTTE": "YT",
  "DEPARTMENTOFMAYOTTE": "YT",
  "DEPARTEMENTDEMAYOTTE": "YT",
  "YT": "YT",
  "MYT": "YT",
  "SOUTHAFRICA": "ZA",
  "RSA": "ZA",
  "SUIDAFRIKA": "ZA",
  "REPUBLICSOUTHAFRICA": "ZA",
  "REPUBLICOFSOUTHAFRICA": "ZA",
  "ZA": "ZA",
  "ZAF": "ZA",
  "ZAMBIA": "ZM",
  "REPUBLICZAMBIA": "ZM",
  "REPUBLICOFZAMBIA": "ZM",
  "ZM": "ZM",
  "ZMB": "ZM",
  "ZIMBABWE": "ZW",
  "REPUBLICZIMBABWE": "ZW",
  "REPUBLICOFZIMBABWE": "ZW",
  "ZW": "ZW",
  "ZWE": "ZW"
}