import { SerializedStyles } from "@emotion/react";

interface IButtonProps {
  label: string;
  buttonStyle?: SerializedStyles;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  classes?: string;
  type?: "button" | "submit" | "reset" | undefined;
}

export const CustomButton = ({
  label,
  buttonStyle,
  onClick,
  classes,
  type,
}: IButtonProps) => {
  return (
    <button type={type} className={classes} css={buttonStyle} onClick={onClick}>
      {label}
    </button>
  );
};
