/* eslint-disable react/no-unknown-property */
import {
  logoTitle,
  navMenu,
  secondaryNavMenu,
  secondaryNavTitle,
} from "./Header.styles";
import Logo from "../../ui/static/Logo.svg";
import Badge from "../../ui/static/badge.png";
import Panda from "../../ui/static/panda.png";
import Fulfilled from "../../ui/static/fullfilled.png";
import { CustomButton } from "../../ui/components/Button";
import { Auth } from "aws-amplify";
import { signOutBtnStyle } from "../OrderDetails/OrderDetails.style";

interface IHeaderProps {
  isAuthorized: boolean;
}

const Header = ({ isAuthorized }: IHeaderProps) => {
  const handleLogout = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return (
    <>
      <div
        css={navMenu}
        className="d-flex justify-content-between aling-items-center px-4 py-2"
      >
        <div className="d-flex align-items-center justify-content-center">
          <img src={Logo} alt="logo" />
          <h1 css={logoTitle} className="ml-2">
            Immutable
          </h1>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          {isAuthorized && (
            <CustomButton
              label="Sign Out"
              classes="px-4 py-2"
              buttonStyle={signOutBtnStyle}
              onClick={() => {
                void handleLogout();
              }}
            />
          )}
        </div>
      </div>
      <div
        css={secondaryNavMenu}
        className="d-flex justify-content-between align-items-center px-5 py-1"
      >
        <div css={secondaryNavTitle}>YubiKey Self Service</div>
        <div className="d-flex align-items-center">
          <div className="d-none d-md-flex flex-column mr-3">
            <span className="align-self-end">
              <img src={Fulfilled} alt="fulfilled by" />
            </span>
            <img src={Panda} alt="Panda" />
          </div>
          <img src={Badge} alt="Badge" width="50px" height="40px" />
        </div>
      </div>
    </>
  );
};

export default Header;
