import { useEffect, useState } from "react";
import { OrderConfirm } from "./OrderSummary";
import { OrderForm } from "./OrderForm";
import { IKeysResponse, IPayload } from "./types";
import { OrderKey } from "./OrderKey";
import { ISelectOptionItem } from "../../ui/components/Select";
import { modifyKeys } from "../helpers";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useFetch } from "../../hooks/fetch.hook";
import { getKeys } from "./_api";

export const OrderDetails = () => {
  const { request } = useFetch();

  const [isCustomAddress, setCustomAddress] = useState<boolean>(false);
  const [isCountrySelected, setCountrySelected] = useState<boolean>(false);
  const [isNewKey, setNewKey] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [data, setData] = useState<IPayload>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    delivery_address_full: "",
    delivery_address_id: "",
    delivery_address_1: "",
    delivery_address_2: "",
    delivery_city: "",
    delivery_state: "",
    delivery_postal_code: "",
    delivery_country: "",
    delivery_country_code: "",
    item_name: "",
    item_code: "",
    delivery_description: "",
  });
  const [keys, setKeys] = useState<ISelectOptionItem[]>([]);

  useEffect(() => {
    void request(getKeys).then((keyResponse: IKeysResponse[]) => {
      if (!keyResponse) return;
      setKeys(modifyKeys(keyResponse));
    });
  }, [request]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleNextStep = () => setStep(step + 1);
  const handleBack = () => setStep(step - 1);

  const steps = [
    <OrderForm
      handleNextStep={handleNextStep}
      key={0}
      data={data}
      setData={setData}
      isCustomAddress={isCustomAddress}
      setCustomAddress={setCustomAddress}
      setCountrySelected={setCountrySelected}
      isCountrySelected={isCountrySelected}
    />,
    <OrderKey
      key={1}
      data={data}
      setData={setData}
      handleNextStep={handleNextStep}
      handleBack={handleBack}
      keys={keys}
      isNewKey={isNewKey}
      setNewKey={setNewKey}
    />,
    <OrderConfirm key={2} data={data} handleBack={handleBack} />,
  ];

  const getStepContent = () => steps[step];

  return <div className="mb-5">{getStepContent()}</div>;
};
