import React, { useCallback, forwardRef } from "react";
import { Input as MuiInput } from "@mui/material";
import { useField } from "formik";
import { getTip } from "../helpers";
import { Done as DoneIcon, Close as CloseIcon } from "@mui/icons-material";

interface IInputProps {
  inputClassName?: string;
  label?: string;
  tip?: JSX.Element | undefined;
  multiline?: boolean;
  value?: string;
  rows?: number;
  classes?: string;
  id: string;
  touched?: boolean;
}

export const Input = forwardRef(function (
  {
    inputClassName = "",
    classes = "",
    label,
    tip,
    multiline,
    rows,
    value,
    id,
    touched,
    ...other
  }: IInputProps,
  ref
) {
  return (
    <div className={`mb-3 w-100 ${classes}`}>
      <div
        className="pt-2 px-4 pb-0 rounded bg-white"
        style={{
          borderBottom: "1px solid #1F8290",
        }}
      >
        {label && <div className="text-muted">{label}</div>}
        <MuiInput
          id={id}
          className={`w-100 ${inputClassName}`}
          disableUnderline
          multiline={multiline}
          inputRef={ref}
          rows={rows}
          value={value}
          data-testid="input"
          endAdornment={
            value && touched ? (
              tip ? (
                <CloseIcon fontSize="large" color="error" />
              ) : (
                <DoneIcon fontSize="large" color="success" />
              )
            ) : undefined
          }
          {...other}
        />
      </div>
      {tip && <div>{tip}</div>}
    </div>
  );
});

interface IMappedInputProps {
  name: string;
  tip?: string | undefined;
  label: string;
  disabled?: boolean;
  classes?: string;
  touched?: boolean;
}

export function MappedInput(props: IMappedInputProps) {
  const [field, meta] = useField(props.name);

  const getInputClasses = useCallback(() => {
    if (meta.touched && meta.error) {
      return "is-invalid";
    }

    if (meta.touched && !meta.error) {
      return "is-valid";
    }

    return "";
  }, [meta]);
  return (
    <Input
      {...field}
      {...props}
      id={props.name}
      inputClassName={getInputClasses()}
      tip={getTip(meta, props.tip)}
    />
  );
}

Input.displayName = "Input";

export default Input;
