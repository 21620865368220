/* eslint-disable react/no-unknown-property */
import { Dispatch, SetStateAction } from "react";
import { Form, Formik, FormikProps } from "formik";
import { IPayload } from "./types";
import { Footer } from "../Footer/Footer";
import { ISelectOptionItem, MappedSelect } from "../../ui/components/Select";
import {
  buttonMenu,
  defaultBtnStyle,
  headerStyle,
  mainText,
} from "./OrderDetails.style";
import { CustomButton } from "../../ui/components/Button";
import { OrderkeySchema } from "../../schemas";
import { descriptionOptions } from "./constants";

interface IOrderKeyProps {
  handleNextStep: () => void;
  handleBack: () => void;
  data: IPayload;
  setData: Dispatch<SetStateAction<IPayload>>;
  keys: ISelectOptionItem[];
  isNewKey: boolean;
  setNewKey: Dispatch<SetStateAction<boolean>>;
}

interface IFormikValues {
  item_name: string;
  item_code: string;
  delivery_description: string;
  new_key: boolean;
}

export const OrderKey = ({
  handleNextStep,
  handleBack,
  data,
  setData,
  keys,
  isNewKey,
  setNewKey,
}: IOrderKeyProps) => {
  const initialValues: IFormikValues = {
    item_name: data.item_name,
    item_code:
      data.item_code === ""
        ? keys.filter((key) => key.isDefault === true)[0].value
        : data.item_code,
    delivery_description: data.delivery_description,
    new_key: isNewKey,
  };

  const handleSubmit = (values: IFormikValues) => {
    setData((prev) => ({
      ...prev,
      item_code: values.item_code,
      item_name: keys.filter((key) => key.value === values.item_code)[0].label,
      delivery_description: values.delivery_description,
    }));
    handleNextStep();
  };

  const itemChangeHandler = (
    item_code: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setFieldValue("item_code", item_code);
    if (item_code !== keys.filter((key) => key.isDefault === true)[0].value) {
      setNewKey(true);
      setFieldValue("new_key", true);
    } else {
      setNewKey(false);
      setFieldValue("new_key", false);
    }
  };

  const descriptionChangeHandler = (
    text: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => setFieldValue("delivery_description", text);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={OrderkeySchema}
        onSubmit={handleSubmit}
      >
        {({ values }: FormikProps<IFormikValues>) => (
          <Form>
            <section className="px-5">
              <h1 css={mainText} className="text-center">
                Now to select your preferred Yubikey type
              </h1>
              <div>
                <p css={headerStyle}>Select your Yubikey</p>
                <MappedSelect
                  name="item_code"
                  options={keys}
                  value={values.item_code}
                  onChange={itemChangeHandler}
                />
              </div>
              {isNewKey && (
                <div>
                  <p css={headerStyle}>Why are you replacing your key?</p>
                  <MappedSelect
                    name="delivery_description"
                    options={descriptionOptions}
                    value={values.delivery_description}
                    onChange={descriptionChangeHandler}
                  />
                </div>
              )}
              <div
                className="d-flex justify-content-between align-items-center"
                css={buttonMenu}
              >
                <CustomButton
                  label="Back"
                  buttonStyle={defaultBtnStyle}
                  type="button"
                  onClick={handleBack}
                />
                <CustomButton
                  label="Next"
                  buttonStyle={defaultBtnStyle}
                  type="submit"
                />
              </div>
            </section>
          </Form>
        )}
      </Formik>
      <Footer />
    </>
  );
};
