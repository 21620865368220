const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_qWKxTKWVC",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "4qgmavbcg2b6cstgf6duko4f2u",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: "dev.immutable.trustpanda.com",
      // OPTIONAL - Cookie path
      //path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
      // CSRF
      sameSite: "lax",
    },
    oauth: {
      domain: "immutable-dev-up.auth.ap-southeast-2.amazoncognito.com",
      scope: ["email", "openid", "profile"],
      redirectSignIn: "https://dev.immutable.trustpanda.com",
      redirectSignOut: "https://dev.immutable.trustpanda.com",
      responseType: "token",
    },
  },
  ApiUrl: {
    Rest: "https://api.dev.immutable.trustpanda.com",
    WebSocket: "https://api.dev.immutable.trustpanda.com",
  },
  loqate: {
    api_key: "MN71-JP16-MJ29-NK46",
  },
};

export default config;
