import { CustomButton } from "../../ui/components/Button";
import { useNavigate } from "react-router-dom";
import {
  mainText,
  trackingBtnStyle,
  trackingTextStyle,
} from "./OrderDetails.style";

export const OrderConfirm = () => {
  const navigate = useNavigate();
  return (
    <section className="text-center p-3">
      <p css={mainText}>
        Your Immutable YubiKey <br className="d-block d-md-none" />
        is on its way!
      </p>
      <p css={trackingTextStyle}>
        We know you’re excited to receive your key and we’ll do our best to get
        it to you as quickly as possible.
      </p>
      <CustomButton
        label="Order Again"
        onClick={() => navigate("/")}
        buttonStyle={trackingBtnStyle}
        classes="text-uppercase mt-5"
      />
    </section>
  );
};
