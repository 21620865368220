import React from "react";
import { Select as MuiSelect, MenuItem } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { getTip } from "../helpers";

export interface ISelectOptionItem {
  value: string;
  label: string;
  isDefault?: boolean;
}

interface ISelectProps {
  label?: string;
  options: ISelectOptionItem[];
  defaultValue?: string;
  multiple?: boolean;
  value: string;
  tip?: JSX.Element | undefined;
  id?: string;
  onChange: (
    value: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void;
}

const Select = ({
  label = "",
  options,
  tip,
  value,
  onChange,
  ...other
}: ISelectProps) => {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      {label && <div className="text-muted">{label}</div>}
      <MuiSelect
        className={`w-100 bg-white`}
        value={value}
        MenuProps={{ disableScrollLock: true }}
        onChange={(e) => onChange(e.target.value, setFieldValue)}
        {...other}
      >
        {(options || []).map(({ value, label }) => (
          // eslint-disable-next-line react/no-children-prop
          <MenuItem key={value} value={value} children={label} />
        ))}
      </MuiSelect>
      {tip && <div style={{ fontSize: "1.2rem" }}>{tip}</div>}
    </>
  );
};

interface IMappedSelectProps {
  name: string;
  tip?: string | undefined;
  label?: string;
  value: string;
  options: ISelectOptionItem[];
  onChange: (
    value: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void;
}

export function MappedSelect(props: IMappedSelectProps) {
  const [field, meta] = useField(props.name);
  return (
    <div className={`form-group`}>
      <Select {...field} {...props} tip={getTip(meta, props.tip)} />
    </div>
  );
}

export default Select;
