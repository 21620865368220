import { css } from "@emotion/react";

export const headingTitle = css({
  fontSize: "1.6rem",
  fontWeight: "600",
  lineHeight: "36px",
});

export const questionStyle = css({
  fontSize: "1.3rem",
  fontWeight: "600",
  color: "#6C757D",
});

export const answerStyle = css({
  fontSize: "1.2rem",
});

export const footerWrapper = css({
  marginTop: "250px !important",
  backgroundColor: "#ececed",
});
