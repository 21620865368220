import React, { Dispatch, SetStateAction } from "react";
import { useFormikContext } from "formik";
import { IFormikValues } from "./OrderForm";
import { Autocomplete, IOptionItem } from "../../ui/components/Autocomplete";

interface ICountryAutocompleteProps {
  countries: Array<IOptionItem>;
  isCustomAddress: boolean;
  countryCode: string;
  setCountryCode: Dispatch<SetStateAction<string>>;
}

export const CountryAutocomplete = ({
  countries,
  isCustomAddress,
  countryCode,
  setCountryCode,
}: ICountryAutocompleteProps) => {
  const { values, setFieldValue, errors } = useFormikContext<IFormikValues>();

  const handleChangeCountry = () => {
    setFieldValue("delivery_address_full", "");
    setFieldValue("delivery_address_1", "");
    setFieldValue("delivery_address_2", "");
    setFieldValue("delivery_city", "");
    setFieldValue("delivery_state", "");
    setFieldValue("delivery_postal_code", "");
  };
  return (
    <div className="my-4">
      <Autocomplete
        options={countries}
        defaultValue={{
          value: values.delivery_country_code,
          label: values.delivery_country,
        }}
        title="Country"
        name_label="delivery_country"
        name_value="delivery_country_code"
        placeholder="Please start typing..."
        disabled={isCustomAddress ? isCustomAddress : false}
        setFieldValue={setFieldValue}
        isAddressSearch={false}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        customChangeHandler={handleChangeCountry}
      />
      {errors.delivery_country ? (
        <span className="text-danger mt-1">{errors.delivery_country}</span>
      ) : null}
    </div>
  );
};
