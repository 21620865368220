export const faqQuestions = [
  {
    title: "Can I choose the type of key I’m getting?",
    text: "You sure can, please select the key you want on the next screen.",
  },
  {
    title: "Where can I ship my key to?",
    text: "Trust Panda will arrange delivery anywhere in the world (except for embargoed countries).",
  },
  {
    title: "Who is Trust Panda?",
    text: "Trust Panda is Yubico’s largest only multi geography authorised ecommerce partner. At Immutable we wanted to make sure you had a great experience and we know Trust Panda will take care of making sure your key gets to you.",
  },
  {
    title: "Where will my YubiKey ship from?",
    text: "Trust Panda will ship your Immutable YubiKey absolutely free of charge from one of their global distribution locations. ",
  },
  {
    title: "What happens if I lose my key?",
    text: "Let Workplace Support know so they can disable your key, sign in with Okta and reorder (maximum once per year). Otherwise contact Workplace Support and they’ll get you sorted.",
  },
];
